export const actionTypes = {
  // general
  SHOW_DIALOG: 'SHOW_DIALOG',
  HIDE_DIALOG: 'HIDE_DIALOG',
  SETTING_CURRENT_ROUTE: 'SETTING_CURRENT_ROUTE',
  UPDATE_PAGE_ROW_COUNT: 'UPDATE_PAGE_ROW_COUNT',
  SET_LOADED_PAGE: 'SET_LOADED_PAGE',
  SET_PAGE_INPUT_TOOLTIP: 'SET_PAGE_INPUT_TOOLTIP',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_BEST_FIT: 'SET_BEST_FIT',
  UPDATING_MAIL_STATUS: 'UPDATING_MAIL_STATUS',
  UPDATING_MAIL_STATUS_FAILED: 'UPDATING_MAIL_STATUS_FAILED',
  UPDATED_MAIL_STATUS: 'UPDATED_MAIL_STATUS',
  SET_SELECTED_ROWS: 'SET_SELECTED_ROWS',
  SET_TABLE_FILTER_DISPLAY_TYPE: 'SET_TABLE_FILTER_DISPLAY_TYPE',
  TOGGLE_SELECT_ALL_ROWS: 'TOGGLE_SELECT_ALL_ROWS',
  SET_FILTERED_DATA: 'SET_FILTERED_DATA',
  ENABLE_ENHANCED_ADMIN: 'ENABLE_ENHANCED_ADMIN',
  REFRESH_SIDE_NAV: 'REFRESH_SIDE_NAV',
  UPDATE_SELECTED_SIDENAV_ELEMENT: 'UPDATE_SELECTED_SIDENAV_ELEMENT',
  ADDING_TO_FAVORITE: 'ADDING_TO_FAVORITE',
  ADDED_TO_FAVORITE: 'ADDED_TO_FAVORITE',
  ADDING_TO_FAVORITE_FAILED: 'ADDING_TO_FAVORITE_FAILED',
  FETCHING_SIDEBAR_MENU: 'FETCHING_SIDEBAR_MENU',
  FETCHED_SIDEBAR_MENU: 'FETCHED_SIDEBAR_MENU',
  FETCHING_SIDEBAR_MENU_FAILED: 'FETCHING_SIDEBAR_MENU_FAILED',
  UPDATE_SELECTED_CUSTOMER: 'UPDATE_SELECTED_CUSTOMER',
  SHOW_CUSTOMER_INFO_CONTAINER: 'SHOW_CUSTOMER_INFO_CONTAINER',
  FETCHING_MESSAGES: 'FETCHING_MESSAGES',
  FETCHED_MESSAGES: 'FETCHED_MESSAGES',
  FETCHING_MESSAGES_FAILED: 'FETCHING_MESSAGES_FAILED',
  UPDATE_VIEW_MESSAGES_SKIP: 'UPDATE_VIEW_MESSAGES_SKIP',
  UPDATE_VIEW_MESSAGES_PAGE_SIZE: 'UPDATE_VIEW_MESSAGES_PAGE_SIZE',
  EXPORTING_MESSAGES: 'EXPORTING_MESSAGES',
  EXPORTED_MESSAGES: 'EXPORTED_MESSAGES',
  EXPORTING_MESSAGES_FAILED: 'EXPORTING_MESSAGES_FAILED',

  // user
  FETCHING_MANAGED_USERS: 'FETCHING_MANAGED_USERS_LIST',
  FETCHED_MANAGED_USERS: 'FETCHED_MANAGED_USERS',
  FETCHING_MANAGED_USERS_FAILED: 'FETCHING_MANAGED_USERS_FAILED',
  FETCHING_USER_LIST: 'FETCHING_USER_LIST',
  FETCHED_USER_LIST: 'FETCHED_USER_LIST',
  FETCHING_USER_LIST_FAILED: 'FETCHING_USER_LIST_FAILED',
  FETCHING_USER_SETTINGS: 'FETCHING_USER_SETTINGS',
  FETCHED_USER_SETTINGS: 'FETCHED_USER_SETTINGS',
  FETCHING_USER_SETTINGS_FAILED: 'FETCHING_USER_SETTINGS_FAILED',
  FETCHED_UDF_DETAILS: 'FETCHED_UDF_DETAILS',
  FETCHING_UDF_DETAILS: 'FETCHING_UDF_DETAILS',
  FETCHING_UDF_DETAILS_FAILED: 'FETCHING_UDF_DETAILS_FAILED',

  // treenode
  FETCHING_TREE_NODES: 'FETCHING_TREE_NODES',
  FETCHED_TREE_NODES: 'FETCHED_TREE_NODES',
  FETCHING_TREE_NODES_FAILED: 'FETCHING_TREE_NODES_FAILED',

  // mailbox
  FETCHED_MAILBOX_DATA: 'FETCHED_MAILBOX_DATA',
  FETCHING_MAILBOX_DATA_FAILED: 'FETCHING_MAILBOX_DATA_FAILED',
  FETCHING_MAILBOX_DATA: 'FETCHING_MAILBOX_DATA',
  SET_USER_OR_MAIL: 'SET_USER_OR_MAIL',
  SET_VIEW_NAME: 'SET_VIEW_NAME',
  SET_ALL_MAIL: 'SET_ALL_MAIL',
  SET_MAILBOX_SKIP: 'SET_MAILBOX_SKIP',
  DELETING_MAILS: 'DELETING_MAILS',
  MAILS_DELETED: 'MAILS_DELETED',
  DELETING_MAILS_FAILED: 'DELETING_MAILS_FAILED',
  UPDATING_MAILBOX_SELECTED_ROWS: 'UPDATING_MAILBOX_SELECTED_ROWS',
  MAILBOX_SELECTED_ROWS_UPDATED: 'MAILBOX_SELECTED_ROWS_UPDATED',
  UPDATED_MAILBOX_SELECTED_ROWS: 'UPDATED_MAILBOX_SELECTED_ROWS',
  EXPORTING_MAILBOX_TO_EXCEL: 'EXPORTING_MAILBOX_TO_EXCEL',
  EXPORTED_MAILBOX_TO_EXCEL: 'EXPORTED_MAILBOX_TO_EXCEL',
  EXPORTING_MAILBOX_TO_EXCEL_FAILED: 'EXPORTING_MAILBOX_TO_EXCEL_FAILED',
  UPDATE_MAIL_SETTING_KEY: 'UPDATE_MAIL_SETTING_KEY',
  UPDATE_MAIL_SETTING_OBJECT: 'UPDATE_MAIL_SETTING_OBJECT',
  UPDATE_MAIL_USER_KEY: 'UPDATE_MAIL_USER_KEY',
  UPDATE_MAIL_SORT_QUERY: 'UPDATE_MAIL_SORT_QUERY',
  CLEAR_MAILBOX_DATA: 'CLEAR_MAILBOX_DATA',
  CLEAN_MAILBOX_DATA: 'CLEAN_MAILBOX_DATA',
  FETCHING_MAILBOX_DROPDOWN_ITEMS: 'FETCHING_MAILBOX_DROPDOWN_ITEMS',
  FETCHED_MAILBOX_DROPDOWN_ITEMS: 'FETCHED_MAILBOX_DROPDOWN_ITEMS',
  FETCHING_MAILBOX_DROPDOWN_ITEMS_FAILED:
    'FETCHING_MAILBOX_DROPDOWN_ITEMS_FAILED',

  // communication
  FETCHED_COMMUNICATIONS: 'FETCHED_COMMUNICATIONS',
  FETCHING_COMMUNICATIONS_FAILED: 'FETCHING_COMMUNICATIONS_FAILED',
  FETCHING_COMMUNICATIONS: 'FETCHING_COMMUNICATIONS',
  SET_INQUIRIES_ACTIVITIES_PAGE: 'SET_INQUIRIES_ACTIVITIES_PAGE',
  SET_SHOW_ARCHIVED_ACTIVITIES: 'SET_SHOW_ARCHIVED_ACTIVITIES',
  SET_SHOW_ALL_HIGHLIGHTED_ACTIVITIES: 'SET_SHOW_ALL_HIGHLIGHTED_ACTIVITIES',
  FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW:
    'FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW',
  FETCHED_ACTIVITIES_FOR_HIGHLIGHTED_ROW:
    'FETCHED_ACTIVITIES_FOR_HIGHLIGHTED_ROW',
  FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW_FAILED:
    'FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW_FAILED',
  FETCHING_ACTIVITY_INFO: 'FETCHING_ACTIVITY_INFO',
  FETCHED_ACTIVITY_INFO: 'FETCHED_ACTIVITY_INFO',
  FETCHING_ACTIVITY_INFO_FAILED: 'FETCHING_ACTIVITY_INFO_FAILED',
  UPDATED_COMMUNICATIONS_SELECTED_ROWS: 'UPDATED_COMMUNICATIONS_SELECTED_ROWS',
  UPDATING_COMMUNICATIONS_SELECTED_ROWS:
    'UPDATING_COMMUNICATIONS_SELECTED_ROWS',
  UPDATE_IS_COMMUNICATIONS_ALL_ROWS_SELECTED:
    'UPDATE_IS_COMMUNICATIONS_ALL_ROWS_SELECTED',
  COMMUNICATIONS_BULK_UPDATE_STARTED: 'COMMUNICATIONS_BULK_UPDATE_STARTED',
  COMMUNICATIONS_BULK_UPDATE_COMPLETED: 'COMMUNICATIONS_BULK_UPDATE_COMPLETED',
  COMMUNICATIONS_BULK_UPDATE_FAILED: 'COMMUNICATIONS_BULK_UPDATE_FAILED',
  EXPORTING_COMMUNICATIONS_TO_EXCEL: 'EXPORTING_COMMUNICATIONS_TO_EXCEL',
  EXPORTED_COMMUNICATIONS_TO_EXCEL: 'EXPORTED_COMMUNICATIONS_TO_EXCEL',
  EXPORTING_COMMUNICATIONS_TO_EXCEL_FAILED:
    'EXPORTING_COMMUNICATIONS_TO_EXCEL_FAILED',
  SET_IS_MAIL_NOT_READ: 'SET_IS_MAIL_NOT_READ',
  SET_IS_SHOW_MAIL_ARCHIVED: 'SET_IS_SHOW_MAIL_ARCHIVED',
  SET_IS_SHOW_HIGHLIGHTED_ACTIVITIES: 'SET_IS_SHOW_HIGHLIGHTED_ACTIVITIES',
  SET_VIEW_SETTING_KEY: 'SET_VIEW_SETTING_KEY',
  SET_VIEW_SETTING_OBJECT: 'SET_VIEW_SETTING_OBJECT',
  SET_ALERT_DESCRIPTION: 'SET_ALERT_DESCRIPTION',
  CLEAN_UP_COMMUNICATION: 'CLEAN_UP_COMMUNICATION',

  // activities
  FETCHED_ACTIVITIES: 'FETCHED_ACTIVITIES',
  FETCHING_ACTIVITIES_FAILED: 'FETCHING_ACTIVITIES_FAILED',
  FETCHING_ACTIVITIES: 'FETCHING_ACTIVITIES',
  SET_ACTIVITIES_SKIP: 'SET_ACTIVITIES_SKIP',
  FETCHING_ACTIVITY_REPORT_INFO: 'FETCHING_ACTIVITY_REPORT_INFO',
  FETCHED_ACTIVITY_REPORT_INFO: 'FETCHED_ACTIVITY_REPORT_INFO',
  FETCHING_ACTIVITY_REPORT_INFO_FAILED: 'FETCHING_ACTIVITY_REPORT_INFO_FAILED',
  FETCHING_ACTIVITY_ATTACHMENTS: 'FETCHING_ACTIVITY_ATTACHMENTS',
  FETCHED_ACTIVITY_ATTACHMENTS: 'FETCHED_ACTIVITY_ATTACHMENTS',
  FETCHING_ACTIVITY_ATTACHMENTS_FAILED: 'FETCHING_ACTIVITY_ATTACHMENTS_FAILED',
  FETCHING_ACTIVITY_TYPE_LIST: 'FETCHING_ACTIVITY_TYPE_LIST',
  FETCHED_ACTIVITY_TYPE_LIST: 'FETCHED_ACTIVITY_TYPE_LIST',
  FETCHING_ACTIVITY_TYPE_LIST_FAILED: 'FETCHING_ACTIVITY_TYPE_LIST_FAILED',
  FETCHING_ACTIVITY_TRANSACTIONS: 'FETCHING_ACTIVITY_TRANSACTIONS',
  FETCHED_ACTIVITY_TRANSACTIONS: 'FETCHED_ACTIVITY_TRANSACTIONS',
  FETCHING_ACTIVITY_TRANSACTIONS_FAILED:
    'FETCHING_ACTIVITY_TRANSACTIONS_FAILED',
  FETCHING_TEMPLATE_DROPDOWN_BY_TYPE: 'FETCHING_TEMPLATE_DROPDOWN_BY_TYPE',
  FETCHED_TEMPLATE_DROPDOWN_BY_TYPE: 'FETCHED_TEMPLATE_DROPDOWN_BY_TYPE',
  FETCHING_TEMPLATE_DROPDOWN_BY_TYPE_FAILED:
    'FETCHING_TEMPLATE_DROPDOWN_BY_TYPE_FAILED',
  FETCHING_ACTIVITIES_CONTACTS: 'FETCHING_ACTIVITIES_CONTACTS',
  FETCHED_ACTIVITIES_CONTACTS: 'FETCHED_ACTIVITIES_CONTACTS',
  FETCHING_ACTIVITIES_CONTACTS_FAILED: 'FETCHING_ACTIVITIES_CONTACTS_FAILED',
  ADDING_CONTACT: 'ADDING_CONTACT',
  ADDED_CONTACT: 'ADDED_CONTACT',
  ADDING_CONTACT_FAILED: 'ADDING_CONTACT_FAILED',
  FETCHING_CONTACTS: 'FETCHING_CONTACTS',
  FETCHED_CONTACTS: 'FETCHED_CONTACTS',
  FETCHED_ALL_ACTIVE_CONTACTS: 'FETCHED_ALL_ACTIVE_CONTACTS',
  FETCHING_ALL_ACTIVE_CONTACTS: 'FETCHING_ALL_ACTIVE_CONTACTS',
  FETCHING_CONTACTS_FAILED: 'FETCHING_CONTACTS_FAILED',
  UPDATING_CONTACTS_PAGE_SIZE: 'UPDATING_CONTACTS_PAGE_SIZE',
  UPDATED_CONTACT: 'UPDATED_CONTACT',
  UPDATING_CONTACT: 'UPDATING_CONTACT',
  UPDATING_CONTACT_FAILED: 'UPDATING_CONTACT_FAILED',
  DELETING_CONTACT: 'DELETING_CONTACT',
  DELETED_CONTACT: 'DELETED_CONTACT',
  DELETING_CONTACT_FAILED: 'DELETING_CONTACT_FAILED',
  ADDING_CONTACT_TO_NOTE: 'ADDING_CONTACT_TO_NOTE',
  ADDED_CONTACT_TO_NOTE: 'ADDED_CONTACT_TO_NOTE',
  ATTACHING_TAGGED_INVOICE: 'ATTACHING_TAGGED_INVOICE',
  ATTACHED_TAGGED_INVOICE: 'ATTACHED_TAGGED_INVOICE',
  ATTACHING_TAGGED_INVOICE_FAILED: 'ATTACHING_TAGGED_INVOICE_FAILED',
  FETCHING_DISPUTE_CODES: 'FETCHING_DISPUTE_CODES',
  FETCHED_DISPUTE_CODES: 'FETCHED_DISPUTE_CODES',
  FETCHING_DISPUTE_CODES_FAILED: 'FETCHING_DISPUTE_CODES_FAILED',
  UPDATING_ALL_EDITED_TAGS: 'UPDATING_ALL_EDITED_TAGS',
  UPDATED_ALL_EDITED_TAGS: 'UPDATED_ALL_EDITED_TAGS',
  UPDATING_ALL_EDITED_TAGS_FAILED: 'UPDATING_ALL_EDITED_TAGS_FAILED',
  UPDATING_CHECK_UNCHECK_ATTACHMENT: 'UPDATING_CHECK_UNCHECK_ATTACHMENT',
  UPDATED_CHECK_UNCHECK_ATTACHMENT: 'UPDATED_CHECK_UNCHECK_ATTACHMENT',
  UPDATING_CHECK_UNCHECK_ATTACHMENT_FAILED:
    'UPDATING_CHECK_UNCHECK_ATTACHMENT_FAILED',
  ATTACHING_REPORT: 'ATTACHING_REPORT',
  ATTACHING_REPORT_FAILED: 'ATTACHING_REPORT_FAILED',
  ATTACHED_REPORT: 'ATTACHED_REPORT',
  UPDATE_SHOW_NO_DATA_FOR_REPORT: 'UPDATE_SHOW_NO_DATA_FOR_REPORT',
  CLOSE_TAGGED_INVOICE_POPUP: 'CLOSE_TAGGED_INVOICE_POPUP',

  // accountOverview
  // StatementsOfAccount
  FETCHING_STATEMENT_OF_ACCOUNTS: 'FETCHING_STATEMENT_OF_ACCOUNTS',
  FETCHED_STATEMENT_OF_ACCOUNTS: 'FETCHED_STATEMENT_OF_ACCOUNTS',
  FETCHING_STATEMENT_OF_ACCOUNTS_FAILED:
    'FETCHING_STATEMENT_OF_ACCOUNTS_FAILED',
  UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS:
    'UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS',
  UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS_RESET:
    'UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS_RESET',
  UPDATING_STATEMENTS_OF_ACCOUNT_SELECTED_ROWS:
    'UPDATING_STATEMENTS_OF_ACCOUNT_SELECTED_ROWS',
  EXPORTING_STATEMENTS_TO_EXCEL: 'EXPORTING_STATEMENTS_TO_EXCEL',
  EXPORTED_STATEMENTS_TO_EXCEL: 'EXPORTED_STATEMENTS_TO_EXCEL',
  EXPORTING_STATEMENTS_TO_EXCEL_FAILED: 'EXPORTING_STATEMENTS_TO_EXCEL_FAILED',
  UPDATE_STATEMENT_OF_ACCOUNT_SORT: 'UPDATE_STATEMENT_OF_ACCOUNT_SORT',
  UPDATE_ACCOUNT_SETTING_KEY: 'UPDATE_ACCOUNT_SETTING_KEY',
  UPDATE_ACCOUNT_SETTING_OBJECT: 'UPDATE_ACCOUNT_SETTING_OBJECT',
  CLEAN_UP_STATEMENTS_OF_ACCOUNT_DATA: 'CLEAN_UP_STATEMENTS_OF_ACCOUNT_DATA',
  // activities
  FETCHING_ACTIVITIES_OVERVIEW: 'FETCHING_ACTIVITIES_OVERVIEW',
  FETCHING_EMAIL_TEMPLATE_ACTIVITIES: 'FETCHING_EMAIL_TEMPLATE_ACTIVITIES',
  FETCHED_ACTIVITIES_OVERVIEW: 'FETCHED_ACTIVITIES_OVERVIEW',
  FETCHED_EMAIL_TEMPLATE_ACTIVITIES: 'FETCHED_EMAIL_TEMPLATE_ACTIVITIES',
  FETCHING_ACTIVITIES_OVERVIEW_FAILED: 'FETCHING_ACTIVITIES_OVERVIEW_FAILED',
  FETCHING_EMAIL_TEMPLATE_ACTIVITIES_FAILED:
    'FETCHING_EMAIL_TEMPLATE_ACTIVITIES_FAILED',
  UPDATE_ACTIVITIES_OVERVIEW_SKIP: 'UPDATE_ACTIVITIES_OVERVIEW_SKIP',
  UPDATED_ACTIVITIES_OVERVIEW_SELECTED_ROWS:
    'UPDATED_ACTIVITIES_OVERVIEW_SELECTED_ROWS',
  UPDATING_ACTIVITIES_OVERVIEW_PAGE_SIZE:
    'UPDATING_ACTIVITIES_OVERVIEW_PAGE_SIZE',
  UPDATE_ACTIVITIES_OVERVIEW_PAGE_SIZE: 'UPDATE_ACTIVITIES_OVERVIEW_PAGE_SIZE',
  EXPORTING_ACTIVITIES_TO_EXCEL: 'EXPORTING_ACTIVITIES_TO_EXCEL',
  EXPORTED_ACTIVITIES_TO_EXCEL: 'EXPORTED_ACTIVITIES_TO_EXCEL',
  EXPORTING_ACTIVITIES_TO_EXCEL_FAILED: 'EXPORTING_ACTIVITIES_TO_EXCEL_FAILED',
  SET_ACTIVITIES_OVERVIEW_GLOBAL_FILTER:
    'SET_ACTIVITIES_OVERVIEW_GLOBAL_FILTER',
  UPDATE_ACTIVITIES_OVERVIEW_SORT: 'UPDATE_ACTIVITIES_OVERVIEW_SORT',
  UPDATE_CUSTOMER_DROPDOWN_VALUE_CHANGED:
    'UPDATE_CUSTOMER_DROPDOWN_VALUE_CHANGED',
  UPDATE_ACTIVITIES_OVERVIEW_LOADED_PAGE:
    'UPDATE_ACTIVITIES_OVERVIEW_LOADED_PAGE',
  UPDATE_ACTIVITIES_OVERVIEW_CURRENT_PAGE:
    'UPDATE_ACTIVITIES_OVERVIEW_CURRENT_PAGE',
  IS_EMAIL_TEMPLATE_ACTIVITIES: 'IS_EMAIL_TEMPLATE_ACTIVITIES',
  UPDATE_ACCOUNT_ACTIVITIES_SETTING_KEY:
    'UPDATE_ACCOUNT_ACTIVITIES_SETTING_KEY',
  UPDATE_ACCOUNT_ACTIVITIES_SETTING_OBJECT:
    'UPDATE_ACCOUNT_ACTIVITIES_SETTING_OBJECT',
  CLEAN_UP_ACTIVITIES_OVERVIEW_DATA: 'CLEAN_UP_ACTIVITIES_OVERVIEW_DATA',

  FETCHING_COMMUNICATION_TEMPLATE_ATTACHMENTS:
    'FETCHING_COMMUNICATION_TEMPLATE_ATTACHMENTS',
  FETCHED_COMMUNICATION_TEMPLATE_ATTACHMENTS:
    'FETCHED_COMMUNICATION_TEMPLATE_ATTACHMENTS',
  FETCHING_COMMUNICATION_TEMPLATE_ATTACHMENTS_FAILED:
    'FETCHING_COMMUNICATION_TEMPLATE_ATTACHMENTS_FAILED',

  UPLOADING_TEMPLATE_FILES_ATTACHMENTS: 'UPLOADING_TEMPLATE_FILES_ATTACHMENTS',
  UPLOADED_TEMPLATE_FILES_ATTACHMENTS: 'UPLOADED_TEMPLATE_FILES_ATTACHMENTS',
  UPLOADING_TEMPLATE_FILES_ATTACHMENTS_FAILED:
    'UPLOADING_TEMPLATE_FILES_ATTACHMENTS_FAILED',

  // invoiceLines
  FETCHING_INVOICE_LINES: 'FETCHING_INVOICE_LINES',
  FETCHED_INVOICE_LINES: 'FETCHED_INVOICE_LINES',
  FETCHING_INVOICE_LINES_FAILED: 'FETCHING_INVOICE_LINES_FAILED',

  // AccountNotes
  FETCHING_ACCOUNT_NOTES: 'FETCHING_ACCOUNT_NOTES',
  FETCHED_ACCOUNT_NOTES: 'FETCHED_ACCOUNT_NOTES',
  FETCHING_ACCOUNT_NOTES_FAILED: 'FETCHING_ACCOUNT_NOTES_FAILED',
  DELETING_ACCOUNT_NOTES: 'DELETING_ACCOUNT_NOTES',
  DELETED_ACCOUNT_NOTES: 'DELETED_ACCOUNT_NOTES',
  DELETING_ACCOUNT_NOTES_FAILED: 'DELETING_ACCOUNT_NOTES_FAILED',

  // Application Detail
  FETCHING_APPLICATION_DETAIL: 'FETCHING_APPLICATION_DETAIL',
  FETCHED_APPLICATION_DETAIL: 'FETCHED_APPLICATION_DETAIL',
  FETCHING_APPLICATION_DETAIL_FAILED: 'FETCHING_APPLICATION_DETAIL_FAILED',
  FETCHING_APPLICATION_DETAIL_PAYMENT: 'FETCHING_APPLICATION_DETAIL_PAYMENT',
  FETCHED_APPLICATION_DETAIL_PAYMENT: 'FETCHED_APPLICATION_DETAIL_PAYMENT',
  FETCHING_APPLICATION_DETAIL_PAYMENT_FAILED:
    'FETCHING_APPLICATION_DETAIL_PAYMENT_FAILED',
  UPDATE_APPLICATION_DETAIL_SKIP: 'UPDATE_APPLICATION_DETAIL_SKIP',
  UPDATING_APPLICATION_DETAIL_PAGE_SIZE:
    'UPDATING_APPLICATION_DETAIL_PAGE_SIZE',
  UPDATE_APPLICATION_DETAIL_PAGE_SIZE: 'UPDATE_APPLICATION_DETAIL_PAGE_SIZE',

  // Activity Detail
  FETCHING_ACTIVITY_DETAIL: 'FETCHING_ACTIVITY_DETAIL',
  FETCHED_ACTIVITY_DETAIL: 'FETCHED_ACTIVITY_DETAIL',
  FETCHING_ACTIVITY_DETAIL_FAILED: 'FETCHING_ACTIVITY_DETAIL_FAILED',
  UPDATE_ACTIVITY_DETAIL_SKIP: 'UPDATE_ACTIVITY_DETAIL_SKIP',
  UPDATING_ACTIVITY_DETAIL_PAGE_SIZE: 'UPDATING_ACTIVITY_DETAIL_PAGE_SIZE',
  UPDATE_ACTIVITY_DETAIL_PAGE_SIZE: 'UPDATE_ACTIVITY_DETAIL_PAGE_SIZE',

  // All Accounts
  FETCHED_ALL_ACCOUNTS: 'FETCHED_ALL_ACCOUNTS',
  FETCHING_ALL_ACCOUNTS_FAILED: 'FETCHING_ALL_ACCOUNTS_FAILED',
  FETCHING_ALL_ACCOUNTS: 'FETCHING_ALL_ACCOUNTS',
  SET_ALL_ACCOUNTS_SKIP: 'SET_ALL_ACCOUNTS_SKIP',
  UPDATED_ALL_ACCOUNTS_SELECTED_ROWS: 'UPDATED_ALL_ACCOUNTS_SELECTED_ROWS',
  UPDATING_ALL_ACCOUNTS_SELECTED_ROWS: 'UPDATING_ALL_ACCOUNTS_SELECTED_ROWS',
  UPDATE_IS_ALL_ACCOUNTS_ALL_ROWS_SELECTED:
    'UPDATE_IS_ALL_ACCOUNTS_ALL_ROWS_SELECTED',
  ALL_ACCOUNTS_BULK_UPDATE_STARTED: 'ALL_ACCOUNTS_BULK_UPDATE_STARTED',
  ALL_ACCOUNTS_BULK_UPDATE_COMPLETED: 'ALL_ACCOUNTS_BULK_UPDATE_COMPLETED',
  ALL_ACCOUNTS_BULK_UPDATE_FAILED: 'ALL_ACCOUNTS_BULK_UPDATE_FAILED',
  UPDATE_ALL_ACCOUNTS_PAGE_SIZE: 'UPDATE_ALL_ACCOUNTS_PAGE_SIZE',
  UPDATING_ALL_ACCOUNTS_PAGE_SIZE: 'UPDATING_ALL_ACCOUNTS_PAGE_SIZE',
  EXPORTING_ALL_ACCOUNTS_TO_EXCEL: 'EXPORTING_ALL_ACCOUNTS_TO_EXCEL',
  EXPORTED_ALL_ACCOUNTS_TO_EXCEL: 'EXPORTED_ALL_ACCOUNTS_TO_EXCEL',
  EXPORTING_ALL_ACCOUNTS_TO_EXCEL_FAILED:
    'EXPORTING_ALL_ACCOUNTS_TO_EXCEL_FAILED',
  SET_ALL_ACCOUNTS_FILTER_QUERY: 'SET_COMMUNICATIONS_FILTER_QUERY',
  SET_ALL_ACCOUNTS_GLOBAL_FILTER: 'SET_COMMUNICATIONS_GLOBAL_FILTER',
  SET_ALL_ACCOUNTS_VIEW_SETTING_KEY: 'SET_ALL_ACCOUNTS_VIEW_SETTING_KEY',
  SET_ALL_ACCOUNTS_VIEW_SETTING_OBJECT: 'SET_ALL_ACCOUNTS_VIEW_SETTING_OBJECT',
  UPDATE_ALL_ACCOUNTS_SORT: 'UPDATE_ALL_ACCOUNTS_SORT',
  SET_ALL_ACCOUNTS_LOADING: 'SET_ALL_ACCOUNTS_LOADING',
  FETCHED_ALL_ACCOUNTS_TOTALS: 'FETCHED_ALL_ACCOUNTS_TOTALS',
  FETCHING_ALL_ACCOUNTS_TOTALS_FAILED: 'FETCHING_ALL_ACCOUNTS_TOTALS_FAILED',
  FETCHING_ALL_ACCOUNTS_TOTALS: 'FETCHING_ALL_ACCOUNTS_TOTALS',
  SET_ALL_ACCOUNTS_TOTALS_LOADING: 'SET_ALL_ACCOUNTS_TOTALS_LOADING',
  CLEAN_UP_ALL_ACCOUNTS_DATA: 'CLEAN_UP_ALL_ACCOUNTS_DATA',

  // Schedule Payments
  FETCHED_SCHEDULED_PAYMENTS: 'FETCHED_SCHEDULED_PAYMENTS',
  FETCHING_SCHEDULED_PAYMENTS_FAILED: 'FETCHING_SCHEDULED_PAYMENTS_FAILED',
  FETCHING_SCHEDULED_PAYMENTS: 'FETCHING_SCHEDULED_PAYMENTS',
  EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL:
    'EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL',
  EXPORTED_SCHEDULED_PAYMENTS_TO_EXCEL: 'EXPORTED_SCHEDULED_PAYMENTS_TO_EXCEL',
  EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL_FAILED:
    'EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL_FAILED',
  SET_SCHEDULED_PAYMENTS_VIEW_SETTING_OBJECT:
    'SET_SCHEDULED_PAYMENTS_VIEW_SETTING_OBJECT',
  SET_SCHEDULED_PAYMENTS_LOADING: 'SET_SCHEDULED_PAYMENTS_LOADING',
  APPROVED_SCHEDULED_PAYMENTS: 'APPROVED_SCHEDULED_PAYMENTS',
  APPROVING_SCHEDULED_PAYMENTS_FAILED: 'APPROVING_SCHEDULED_PAYMENTS_FAILED',
  APPROVING_SCHEDULED_PAYMENTS: 'APPROVING_SCHEDULED_PAYMENTS',
  FETCHING_SCHEDULED_PAYMENTS_INVOICES_DETAILS:
    'FETCHING_SCHEDULED_PAYMENTS_INVOICES_DETAILS',
  FETCHED_SCHEDULED_PAYMENTS_INVOICES_DETAILS:
    'FETCHED_SCHEDULED_PAYMENTS_INVOICES_DETAILS',
  FETCHING_SCHEDULED_PAYMENTS_INVOICES_DETAILS_FAILED:
    'FETCHING_SCHEDULED_PAYMENTS_INVOICES_DETAILS_FAILED',
  CLEAN_UP_SCHEDULED_PAYMENTS: 'CLEAN_UP_SCHEDULED_PAYMENTS',

  // Pending Payments Summary
  FETCHED_PENDING_PAYMENTS_SUMMARY: 'FETCHED_PENDING_PAYMENTS_SUMMARY',
  FETCHING_PENDING_PAYMENTS_SUMMARY_FAILED:
    'FETCHING_PENDING_PAYMENTS_SUMMARY_FAILED',
  FETCHING_PENDING_PAYMENTS_SUMMARY: 'FETCHING_PENDING_PAYMENTS_SUMMARY',
  EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL:
    'EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL',
  EXPORTED_PENDING_PAYMENTS_SUMMARY_TO_EXCEL:
    'EXPORTED_PENDING_PAYMENTS_SUMMARY_TO_EXCEL',
  EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL_FAILED:
    'EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL_FAILED',
  SET_PENDING_PAYMENTS_SUMMARY_VIEW_SETTING_KEY:
    'SET_PENDING_PAYMENTS_SUMMARY_VIEW_SETTING_KEY',
  SET_PENDING_PAYMENTS_SUMMARY_LOADING: 'SET_PENDING_PAYMENTS_SUMMARY_LOADING',
  APPROVED_PENDING_PAYMENTS_SUMMARY: 'APPROVED_PENDING_PAYMENTS_SUMMARY',
  APPROVING_PENDING_PAYMENTS_SUMMARY_FAILED:
    'APPROVING_PENDING_PAYMENTS_SUMMARY_FAILED',
  APPROVING_PENDING_PAYMENTS_SUMMARY: 'APPROVING_PENDING_PAYMENTS_SUMMARY',
  SET_PAYMENT_SUMMARY_DASHBOARD_VIEW_SETTING_OBJECT:
    'SET_PAYMENT_SUMMARY_DASHBOARD_VIEW_SETTING_OBJECT',

  // Pending Payments Details
  FETCHING_PENDING_PAYMENTS_DETAILS: 'FETCHING_PENDING_PAYMENTS_DETAILS',
  FETCHED_PENDING_PAYMENTS_DETAILS: 'FETCHED_PENDING_PAYMENTS_DETAILS',
  FETCHING_PENDING_PAYMENTS_DETAILS_FAILED:
    'FETCHING_PENDING_PAYMENTS_DETAILS_FAILED',
  UPDATE_PENDING_PAYMENTS_DETAILS_VIEW_SETTING_KEY:
    'UPDATE_PENDING_PAYMENTS_DETAILS_VIEW_SETTING_KEY',
  EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL:
    'EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL',
  EXPORTED_PENDING_PAYMENTS_DETAILS_TO_EXCEL:
    'EXPORTED_PENDING_PAYMENTS_DETAILS_TO_EXCEL',
  EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL_FAILED:
    'EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL_FAILED',
  FETCHING_PENDING_PAYMENTS_INVOICES_DETAILS:
    'FETCHING_PENDING_PAYMENTS_INVOICES_DETAILS',
  FETCHED_PENDING_PAYMENTS_INVOICES_DETAILS:
    'FETCHED_PENDING_PAYMENTS_INVOICES_DETAILS',
  FETCHING_PENDING_PAYMENTS_INVOICES_DETAILS_FAILED:
    'FETCHING_PENDING_PAYMENTS_INVOICES_DETAILS_FAILED',
  RESEND_PENDING_PAYMENTS: 'RESEND_PENDING_PAYMENTS',
  RESEND_PENDING_PAYMENTS_FAILED: 'RESEND_PENDING_PAYMENTS_FAILED',
  FETCH_WEBHOOK_SETTING: 'FETCH_WEBHOOK_SETTING',
  FETCH_WEBHOOK_SETTING_FAILED: 'FETCH_WEBHOOK_SETTING_FAILED',
  CLEAR_WEBHOOK_STATUS: 'CLEAR_WEBHOOK_STATUS',
  CLEAR_WEBHOOK_STATUS_FAILED: 'CLEAR_WEBHOOK_STATUS_FAILED',
  UPDATING_PENDING_PAYMENT_RECONCILE_STATUS:
    'UPDATING_PENDING_PAYMENT_RECONCILE_STATUS',
  UPDATED_PENDING_PAYMENT_RECONCILE_STATUS:
    'UPDATED_PENDING_PAYMENT_RECONCILE_STATUS',
  UPDATE_PENDING_PAYMENT_RECONCILE_STATUS_FAILED:
    'UPDATE_PENDING_PAYMENT_RECONCILE_STATUS_FAILED',
  SET_PENDING_PAYMENT_IS_RESTRICTED: 'SET_PENDING_PAYMENT_IS_RESTRICTED',
  SET_PENDING_PAYMENTS_ALERT_DESCRIPTION:
    'SET_PENDING_PAYMENTS_ALERT_DESCRIPTION',
  SET_PENDING_PAYMENTS_DETAILS_DASHBOARD_VIEW_SETTING_OBJECT:
    'SET_PENDING_PAYMENTS_DETAILS_DASHBOARD_VIEW_SETTING_OBJECT',
  CLEAN_UP_PENDING_PAYMENTS_SUMMARY: 'CLEAN_UP_PENDING_PAYMENTS_SUMMARY',
  CLEAN_UP_PENDING_PAYMENTS_DETAILS: 'CLEAN_UP_PENDING_PAYMENTS_DETAILS',

  // Expected payments
  FETCHING_EXPECTED_PAYMENTS: 'FETCHING_EXPECTED_PAYMENTS',
  FETCHED_EXPECTED_PAYMENTS: 'FETCHED_EXPECTED_PAYMENTS',
  FETCHING_EXPECTED_PAYMENTS_FAILED: 'FETCHING_EXPECTED_PAYMENTS_FAILED',
  SET_EXPECTED_PAYMENTS_VIEW_SETTING_KEY:
    'SET_EXPECTED_PAYMENTS_VIEW_SETTING_KEY',
  SET_EXPECTED_PAYMENTS_VIEW_SETTING_OBJECT:
    'SET_EXPECTED_PAYMENTS_VIEW_SETTING_OBJECT',
  EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL: 'EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL',
  EXPORTED_EXPECTED_PAYMENTS_TO_EXCEL: 'EXPORTED_EXPECTED_PAYMENTS_TO_EXCEL',
  EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL_FAILED:
    'EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL_FAILED',
  SET_IS_BROKEN_PROMISE: 'SET_IS_BROKEN_PROMISE',
  SET_EXPECTED_PAYMENTS_ALERT_DESCRIPTION:
    'SET_EXPECTED_PAYMENTS_ALERT_DESCRIPTION',
  CLEANUP_EXPECTED_PAYMENTS: 'CLEANUP_EXPECTED_PAYMENTS',

  // Reporting group logs
  FETCHED_REPORTING_GROUP_LOGS: 'FETCHED_SCHEDULED_PAYMENTS',
  FETCHING_REPORTING_GROUP_LOGS_FAILED: 'FETCHING_SCHEDULED_PAYMENTS_FAILED',
  FETCHING_REPORTING_GROUP_LOGS: 'FETCHING_SCHEDULED_PAYMENTS',
  EXPORTING_REPORTING_GROUP_LOGS_TO_EXCEL:
    'EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL',
  EXPORTED_REPORTING_GROUP_LOGS_TO_EXCEL:
    'EXPORTED_SCHEDULED_PAYMENTS_TO_EXCEL',
  EXPORTING_REPORTING_GROUP_LOGS_TO_EXCEL_FAILED:
    'EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL_FAILED',
  SET_REPORTING_GROUP_LOGS_VIEW_SETTING_KEY:
    'SET_SCHEDULED_PAYMENTS_VIEW_SETTING_KEY',
  SET_REPORTING_GROUP_LOGS_LOADING: 'SET_SCHEDULED_PAYMENTS_LOADING',
  SET_REPORTING_GROUP_LOGS_DASHBOARD_VIEW_SETTING_OBJECT:
    'SET_REPORTING_GROUP_LOGS_DASHBOARD_VIEW_SETTING_OBJECT',
  CLEAN_UP_REPORTING_GROUP_LOGS: 'CLEAN_UP_REPORTING_GROUP_LOGS',

  // Projected Cash Receipts
  FETCHED_PROJECTED_CASH_RECEIPTS: 'FETCHED_PROJECTED_CASH_RECEIPTS',
  FETCHING_PROJECTED_CASH_RECEIPTS_FAILED:
    'FETCHING_PROJECTED_CASH_RECEIPTS_FAILED',
  FETCHING_PROJECTED_CASH_RECEIPTS: 'FETCHING_PROJECTED_CASH_RECEIPTS',
  EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL:
    'EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL',
  EXPORTED_PROJECTED_CASH_RECEIPTS_TO_EXCEL:
    'EXPORTED_PROJECTED_CASH_RECEIPTS_TO_EXCEL',
  EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL_FAILED:
    'EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL_FAILED',
  SET_PROJECTED_CASH_RECEIPTS_LOADING: 'SET_PROJECTED_CASH_RECEIPTS_LOADING',
  SET_PROJECTED_CASH_RECEIPTS_VIEW_SETTING_OBJECT:
    'SET_PROJECTED_CASH_RECEIPTS_VIEW_SETTING_OBJECT',
  CLEAN_UP_PROJECTED_CASH_RECEIPTS: 'CLEAN_UP_PROJECTED_CASH_RECEIPTS',

  // Email
  UPDATE_ASSIGN_EMAIL_STATUS: 'UPDATE_ASSIGN_EMAIL_STATUS',
  UPDATED_COMMUNICATION_TRANSACTION_SELECTED_ROWS:
    'UPDATED_COMMUNICATION_TRANSACTION_SELECTED_ROWS',
  UPDATING_COMMUNICATION_TRANSACTION_PAGE_SIZE:
    'UPDATING_COMMUNICATION_TRANSACTION_PAGE_SIZE',

  // FILES & FOLDER

  CREATING_FILE_DIRECTORY: 'CREATING_FILE_DIRECTORY',
  CREATED_FILE_DIRECTORY: 'CREATED_FILE_DIRECTORY',
  CREATING_FILE_DIRECTORY_FAILED: 'CREATING_FILE_DIRECTORY_FAILED',
  FETCHING_FILE_LIST: 'FETCHING_FILE_LIST',
  FETCHED_FILE_LIST: 'FETCHED_FILE_LIST',
  FETCHING_FILE_LIST_FAILED: 'FETCHING_FILE_LIST_FAILED',
  UPLOADING_FILES: 'UPLOADING_FILES',
  UPLOADED_FILES: 'UPLOADED_FILES',
  UPLOADING_FILES_FAILED: 'UPLOADING_FILES_FAILED',
  DELETING_FILE_DIRECTORY: 'DELETING_FILE_DIRECTORY',
  DELETE_FILE_DIRECTORY: 'CREATED_FILE_DIRECTORY',
  DELETING_FILE_DIRECTORY_FAILED: 'DELETING_FILE_DIRECTORY_FAILED',
  DELETING_FILES: 'DELETING_FILES',
  DELETED_FILES: 'DELETED_FILES',
  DELETING_FILES_FAILED: 'DELETING_FILES_FAILED',
  UPLOADING_FILES_ATTACHMENTS: 'UPLOADING_FILES_ATTACHMENTS',
  UPLOADED_FILES_ATTACHMENTS: 'UPLOADED_FILES_ATTACHMENTS',
  UPLOADING_FILES_ATTACHMENTS_FAILED: 'UPLOADING_FILES_ATTACHMENTS_FAILED',

  // INVOICE
  FETCHING_INVOICE_INSTALLMENTS: 'FETCHING_INVOICE_INSTALLMENTS',
  FETCHED_INVOICE_INSTALLMENTS: 'FETCHED_INVOICE_INSTALLMENTS',
  FETCHING_INVOICE_INSTALLMENTS_FAILED: 'FETCHING_INVOICE_INSTALLMENTS_FAILED',
  ADDING_INVOICE_INSTALLMENTS: 'ADDING_INVOICE_INSTALLMENTS',
  ADDED_INVOICE_INSTALLMENTS: 'ADDED_INVOICE_INSTALLMENTS',
  ADDING_INVOICE_INSTALLMENTS_FAILED: 'ADDING_INVOICE_INSTALLMENTS_FAILED',
  DELETING_ALL_INVOICE_INSTALLMENTS: 'DELETING_ALL_INVOICE_INSTALLMENTS',
  DELETED_ALL_INVOICE_INSTALLMENTS: 'DELETED_ALL_INVOICE_INSTALLMENTS',
  DELETE_ALL_INVOICE_INSTALLMENTS_FAILED:
    'DELETE_ALL_INVOICE_INSTALLMENTS_FAILED',

  // Payments And Currencies
  FETCHING_PAYMENT_METHODS: 'FETCHING_PAYMENT_METHODS',
  FETCHED_PAYMENT_METHODS: 'FETCHED_PAYMENT_METHODS',
  FETCHING_PAYMENT_METHODS_FAILED: 'FETCHING_PAYMENT_METHODS_FAILED',

  UPDATE_SELECTED_PAYMENT_METHOD: 'UPDATE_SELECTED_PAYMENT_METHOD',
  UPDATING_SELECTED_PAYMENT_METHOD: 'UPDATING_SELECTED_PAYMENT_METHOD',
  MAKING_PAYMENT: 'MAKING_PAYMENT',
  MADE_PAYMENT: 'MADE_PAYMENT',
  MAKE_PAYMENT_FAILED: 'MAKE_PAYMENT_FAILED',
  ADDING_PAYMENT_METHOD: 'ADDING_PAYMENT_METHOD',
  UPDATING_PAYMENT_METHOD: 'UPDATING_PAYMENT_METHOD',
  UPDATED_PAYMENT_METHOD: 'UPDATED_PAYMENT_METHOD',
  UPDATING_PAYMENT_METHOD_FAILED: 'UPDATING_PAYMENT_METHOD_FAILED',
  ADDED_PAYMENT_METHOD: 'ADDED_PAYMENT_METHOD',
  ADDING_PAYMENT_METHOD_FAILED: 'ADDING_PAYMENT_METHOD_FAILED',
  UPDATE_PAYMENT_INFO_SKIP: 'UPDATE_PAYMENT_INFO_SKIP',
  VIEWING_PAYMENT_INFO: 'VIEWING_PAYMENT_INFO',
  VIEWING_PAYMENT_INFO_FAILED: 'VIEWING_PAYMENT_INFO_FAILED',
  UPDATE_PAYMENT_ERROR_OBJ: 'UPDATE_PAYMENT_ERROR_OBJ',
  UPDATE_ERROR_MESSAGE: 'UPDATE_ERROR_MESSAGE',
  DELETING_PAYMENT_METHOD: 'DELETING_PAYMENT_METHOD',
  DELETED_PAYMENT_METHOD: 'DELETED_PAYMENT_METHOD',
  DELETING_PAYMENT_METHOD_FAILED: 'DELETING_PAYMENT_METHOD_FAILED',
  RESET_NO_PAYMENT_METHOD_FOUND_ERROR: 'RESET_NO_PAYMENT_METHOD_FOUND_ERROR',

  FETCHING_CURRENCIES: 'FETCHING_CURRENCIES',
  FETCHED_CURRENCIES: 'FETCHED_CURRENCIES',
  FETCHING_CURRENCIES_FAILED: 'FETCHING_CURRENCIES_FAILED',
  UPDATE_SELECTED_CURRENCY: 'UPDATE_SELECTED_CURRENCY',
  UPDATING_SELECTED_CURRENCY: 'UPDATING_SELECTED_CURRENCY',

  UPDATING_REDIRECT_URL: 'UPDATING_REDIRECT_URL',
  UPDATED_REDIRECT_URL: 'UPDATED_REDIRECT_URL',

  UPDATE_PAYMENT_INFO_CURRENT_PAGE: 'UPDATE_PAYMENT_INFO_CURRENT_PAGE',
  UPDATE_PAYMENT_INFO_LOADED_PAGE: 'UPDATE_PAYMENT_INFO_LOADED_PAGE',

  // CustomerInfo
  FETCHING_PORTAL_ACCESS: 'FETCHING_PORTAL_ACCESS',
  FETCHED_PORTAL_ACCESS: 'FETCHED_PORTAL_ACCESS',
  FETCHING_PORTAL_ACCESS_FAILED: 'FETCHING_PORTAL_ACCESS_FAILED',
  UPDATE_PORTAL_ACCESS_SKIP: 'UPDATE_PORTAL_ACCESS_SKIP',
  UPDATING_PORTAL_ACCESS_PAGE_SIZE: 'UPDATING_PORTAL_ACCESS_PAGE_SIZE',
  UPDATED_PORTAL_ACCESS_PAGE_SIZE: 'UPDATED_PORTAL_ACCESS_PAGE_SIZE',
  UPDATE_PORTAL_ACCESS_SORT: 'UPDATE_PORTAL_ACCESS_SORT',

  // Tabs

  FETCHING_CREDIT_DETAILS: 'FETCHING_CREDIT_DETAILS',
  FETCHED_CREDIT_DETAILS: 'FETCHED_CREDIT_DETAILS',
  FETCHING_CREDIT_DETAILS_FAILED: 'FETCHING_CREDIT_DETAILS_FAILED',
  SAVING_CREDIT_DETAILS: 'SAVING_CREDIT_DETAILS',
  SAVED_CREDIT_DETAILS: 'SAVED_CREDIT_DETAILS',
  SAVING_CREDIT_DETAILS_FAILED: 'SAVING_CREDIT_DETAILS_FAILED',
  FETCHING_CREDIT_CLASS: 'FETCHING_CREDIT_CLASS',
  FETCHED_CREDIT_CLASS: 'FETCHED_CREDIT_CLASS',
  FETCHING_CREDIT_CLASS_FAILED: 'FETCHING_CREDIT_CLASS_FAILED',

  FETCHING_INFO: 'FETCHING_INFO',
  FETCHED_INFO: 'FETCHED_INFO',
  FETCHING_INFO_FAILED: 'FETCHING_INFO_FAILED',
  SAVING_INFO: 'SAVING_INFO',
  SAVED_INFO: 'SAVED_INFO',
  SAVING_INFO_FAILED: 'SAVING_INFO_FAILED',
  FETCHING_INVOICE_FORM: 'FETCHING_INVOICE_FORM',
  FETCHED_INVOICE_FORM: 'FETCHED_INVOICE_FORM',
  FETCHING_INVOICE_FORM_FAILED: 'FETCHING_INVOICE_FORM_FAILED',

  FETCHING_HISTORY: 'FETCHING_HISTORY',
  FETCHED_HISTORY: 'FETCHED_HISTORY',
  FETCHING_HISTORY_FAILED: 'FETCHING_HISTORY_FAILED',

  FETCHING_ROLLED_UP_HISTORY: 'FETCHING_ROLLED_UP_HISTORY',
  FETCHED_ROLLED_UP_HISTORY: 'FETCHED_ROLLED_UP_HISTORY',
  FETCHING_ROLLED_UP_HISTORY_FAILED: 'FETCHING_ROLLED_UP_HISTORY_FAILED',

  FETCHING_NACM: 'FETCHING_NACM',
  FETCHED_NACM: 'FETCHED_NACM',
  FETCHING_NACM_FAILED: 'FETCHING_NACM_FAILED',

  FETCHING_TRANSACTIONS: 'FETCHING_TRANSACTIONS',
  FETCHED_TRANSACTIONS: 'FETCHED_TRANSACTIONS',
  FETCHING_TRANSACTIONS_FAILED: 'FETCHING_TRANSACTIONS_FAILED',
  FETCHING_RECENT_PAYMENTS: 'FETCHING_RECENT_PAYMENTS',
  FETCHED_RECENT_PAYMENTS: 'FETCHED_RECENT_PAYMENTS',
  FETCHING_RECENT_PAYMENTS_FAILED: 'FETCHING_RECENT_PAYMENTS_FAILED',
  UPDATE_CREDIT_DETAILS_EDIT_MODE: 'UPDATE_CREDIT_DETAILS_EDIT_MODE',
  UPDATE_INFO_EDIT_MODE: 'UPDATE_INFO_EDIT_MODE',
  UPDATE_CONTACTS_EDIT_MODE: 'UPDATE_CONTACTS_EDIT_MODE',
  UPDATE_CUSTOMER_INFO_CONTACTS_SKIP: 'UPDATE_CUSTOMER_INFO_CONTACTS_SKIP',
  UPDATE_CUSTOMER_INFO_CONTACTS_PAGE_SIZE:
    'UPDATE_CUSTOMER_INFO_CONTACTS_PAGE_SIZE',
  UPDATE_CONTACTS_DIALOG: 'UPDATE_CONTACTS_DIALOG',

  // inquiries
  // by-user/payments
  FETCHING_USER_PAYMENTS: 'FETCHING_USER_PAYMENTS',
  FETCHED_USER_PAYMENTS: 'FETCHED_USER_PAYMENTS',
  FETCHING_USER_PAYMENTS_FAILED: 'FETCHING_USER_PAYMENTS_FAILED',
  UPDATE_USER_PAYMENTS_SKIP: 'UPDATE_USER_PAYMENTS_SKIP',
  UPDATE_USER_PAYMENTS_PAGE_SIZE: 'UPDATE_USER_PAYMENTS_PAGE_SIZE',
  EXPORTING_TO_EXCEL_USER_PAYMENTS: 'EXPORTING_TO_EXCEL_USER_PAYMENTS',
  EXPORTED_TO_EXCEL_USER_PAYMENTS: 'EXPORTED_TO_EXCEL_USER_PAYMENTS',
  EXPORTING_TO_EXCEL_USER_PAYMENTS_FAILED:
    'EXPORTING_TO_EXCEL_USER_PAYMENTS_FAILED',
  SET_USER_PAYMENTS_VIEW_SETTING_OBJECT:
    'SET_USER_PAYMENTS_VIEW_SETTING_OBJECT',
  CLEAN_UP_USER_PAYMENTS: 'CLEAN_UP_USER_PAYMENTS',

  // inquiries
  // by-user/invoices
  FETCHING_USER_INVOICES: 'FETCHING_USER_INVOICES',
  FETCHED_USER_INVOICES: 'FETCHED_USER_INVOICES',
  FETCHING_USER_INVOICES_FAILED: 'FETCHING_USER_INVOICES_FAILED',
  UPDATE_USER_INVOICES_SKIP: 'UPDATE_USER_INVOICES_SKIP',
  UPDATE_USER_INVOICES_PAGE_SIZE: 'UPDATE_USER_INVOICES_PAGE_SIZE',
  EXPORTING_TO_EXCEL_USER_INVOICES: 'EXPORTING_TO_EXCEL_USER_INVOICES',
  EXPORTED_TO_EXCEL_USER_INVOICES: 'EXPORTED_TO_EXCEL_USER_INVOICES',
  EXPORTING_TO_EXCEL_USER_INVOICES_FAILED:
    'EXPORTING_TO_EXCEL_USER_INVOICES_FAILED',
  UPDATE_USER_INVOICES_SETTING_KEY: 'UPDATE_USER_INVOICES_SETTING_KEY',
  SET_USER_INVOICES_VIEW_SETTING_OBJECT:
    'SET_USER_INVOICES_VIEW_SETTING_OBJECT',
  CLEANUP_USER_INVOICE_DATA: 'CLEANUP_USER_INVOICE_DATA',

  // follow-up
  FETCHING_FOLLOW_UP: 'FETCHING_FOLLOW_UP',
  FETCHED_FOLLOW_UP: 'FETCHED_FOLLOW_UP',
  FETCHING_FOLLOW_UP_FAILED: 'FETCHING_FOLLOW_UP_FAILED',
  UPDATE_FOLLOW_UP_SKIP: 'UPDATE_FOLLOW_UP_SKIP',
  UPDATE_FOLLOW_UP_PAGE_SIZE: 'UPDATE_FOLLOW_UP_PAGE_SIZE',
  EXPORTING_TO_EXCEL_FOLLOW_UP: 'EXPORTING_TO_EXCEL_FOLLOW_UP',
  EXPORTED_TO_EXCEL_FOLLOW_UP: 'EXPORTED_TO_EXCEL_FOLLOW_UP',
  EXPORTING_TO_EXCEL_FOLLOW_UP_FAILED: 'EXPORTING_TO_EXCEL_FOLLOW_UP_FAILED',
  UPDATE_FOLLOW_UP_SETTING_KEY: 'UPDATE_FOLLOW_UP_SETTING_KEY',
  SET_FOLLOWUP_DASHBOARD_VIEW_SETTING_OBJECT:
    'SET_FOLLOWUP_DASHBOARD_VIEW_SETTING_OBJECT',
  CLEAN_UP_FOLLOW_UP: 'CLEAN_UP_FOLLOW_UP',

  // best activity
  FETCHING_BEST_ACTIVITY: 'FETCHING_BEST_ACTIVITY',
  FETCHED_BEST_ACTIVITY: 'FETCHED_BEST_ACTIVITY',
  FETCHING_BEST_ACTIVITY_FAILED: 'FETCHING_BEST_ACTIVITY_FAILED',

  // dashboard stats
  FETCHING_HOME_DASHBOARD_STATS: 'FETCHING_HOME_DASHBOARD_STATS',
  FETCHED_HOME_DASHBOARD_STATS: 'FETCHED_HOME_DASHBOARD_STATS',
  FETCHING_HOME_DASHBOARD_STATS_FAILED: 'FETCHING_HOME_DASHBOARD_STATS_FAILED',

  // user alert summary
  FETCHING_USER_ALERT_SUMMARY: 'FETCHING_USER_ALERT_SUMMARY',
  FETCHED_USER_ALERT_SUMMARY: 'FETCHED_USER_ALERT_SUMMARY',
  FETCHING_USER_ALERT_SUMMARY_FAILED: 'FETCHING_USER_ALERT_SUMMARY_FAILED',

  // last sync data
  FETCHING_LAST_SYNC_DATA: 'FETCHING_LAST_SYNC_DATA',
  FETCHED_LAST_SYNC_DATA: 'FETCHED_LAST_SYNC_DATA',
  FETCHING_LAST_SYNC_DATA_FAILED: 'FETCHING_LAST_SYNC_DATA_FAILED',

  // drafts dashboard
  FETCHING_DRAFT_DASHBOARD: 'FETCHING_DRAFT_DASHBOARD',
  FETCHED_DRAFT_DASHBOARD: 'FETCHED_DRAFT_DASHBOARD',
  FETCHING_DRAFT_DASHBOARD_FAILED: 'FETCHING_DRAFT_DASHBOARD_FAILED',
  UPDATE_DRAFT_SETTING_KEY: 'UPDATE_DRAFT_SETTING_KEY',
  EXPORTING_DRAFTS_TO_EXCEL: 'EXPORTING_DRAFTS_TO_EXCEL',
  EXPORTED_DRAFTS_TO_EXCEL: 'EXPORTED_DRAFTS_TO_EXCEL',
  EXPORTING_TO_EXCEL_DRAFTS_FAILED: 'EXPORTING_TO_EXCEL_FOLLOW_UP_FAILED',
  DELETING_DRAFTS_ACTIVITY: 'DELETE_DRAFTS_ACTIVITY',
  DELETING_DRAFTS_ACTIVITY_FAILED: 'DELETING_DRAFTS_ACTIVITY_FAILED',
  DELETED_DRAFTS_ACTIVITY: 'DELETED_DRAFTS_ACTIVITY',
  SET_DRAFT_DASHBOARD_VIEW_SETTING_OBJECT:
    'SET_DRAFT_DASHBOARD_VIEW_SETTING_OBJECT',
  CLEAN_UP_DRAFT_DASHBOARD: 'CLEAN_UP_DRAFT_DASHBOARD',

  // JWT Tokens
  FETCHING_JWT_TOKENS: 'FETCHING_JWT  _TOKENS',
  FETCHED_JWT_TOKENS: 'FETCHED_JWT_TOKENS',
  FETCHING_JWT_TOKENS_FAILED: 'FETCHING_JWT_TOKENS_FAILED',

  // user contacts
  FETCHING_USER_CONTACTS: 'FETCHING_USER_CONTACTS',
  FETCHED_USER_CONTACTS: 'FETCHED_USER_CONTACTS',
  FETCHING_USER_CONTACTS_FAILED: 'FETCHING_USER_CONTACTS_FAILED',
  EXPORTING_USER_CONTACTS_TO_EXCEL: 'EXPORTING_USER_CONTACTS_TO_EXCEL',
  EXPORTED_USER_CONTACTS_TO_EXCEL: 'EXPORTED_USER_CONTACTS_TO_EXCEL',
  EXPORTING_USER_CONTACTS_TO_EXCEL_FAILED:
    'EXPORTING_USER_CONTACTS_TO_EXCEL_FAILED',
  UPDATE_USER_CONTACTS_SETTING_KEY: 'UPDATE_USER_CONTACTS_SETTING_KEY',
  SET_CONTACTS_VIEW_SETTING_OBJECT: 'SET_CONTACTS_VIEW_SETTING_OBJECT',
  CLEAN_UP_USER_CONTACTS_DATA: 'CLEAN_UP_USER_CONTACTS_DATA',
};
