import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {getConfig} from '../lib/utils';

const config = getConfig();

export const baseApi = createApi({
  tagTypes: [
    'ActivityTemplate',
    'CommunicationTemplate',
    'DisputeCode',
    'SalesRep',
    'ReportingGroups',
    'Report',
    'MessageSettings',
    'AdminCompany',
    'MerchantAccount',
    'WebHook',
    'User',
  ],
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiEndpoint,
    prepareHeaders: headers => {
      const idToken = sessionStorage.getItem('idToken');
      if (idToken) {
        headers.set('Authorization', `Bearer ${idToken}`);
      }
      headers.set('Cache-Control', 'no-cache');
      headers.set('Pragma', 'no-cache');
      headers.set('Expires', '-1');
      return headers;
    },
  }),
  endpoints: () => ({}), // We'll add endpoints in specific API slices
});
