export const constants = {
  // todo:  remove this variable STATE_MUTATION_ERROR once we fix the redux state issue
  STATE_MUTATION_ERROR: 'A state mutation was detected between dispatches',
  SORRY: 'Sorry',
  FEATURE_NOT_AVAILABLE:
    'Preview Feature: This feature is currently under development.',
  AN_ERROR_OCCURED_WHILE_PROCESSING_THIS_FEATURE:
    'An error occured while processing this feature.',
  PLEASE_TRY_AGAIN_LATER:
    'To use this feature now, please uncheck preview option and try again.',
  BULK_ACTIVITY_MAIL_CONFIRMATION_PART1: 'Warning! You Have Selected ',
  BULK_ACTIVITY_MAIL_CONFIRMATION_PART2:
    ' customers(s) to email. Once completed, this cannot be undone. Continue?',
  SHOW_ALL_ACTIVITIES_FOR_HIGHLIGHTED_ROW:
    'Show all activities for highlighted row',
  RESTRICTED_TO_ALERT_MAIL_NOT_READ: 'Restricted to Alert Mail Not Read',
  RESTRICTED_TO_ALERT: 'Restricted to Alert',
  SHOW_ARCHIVED_ACTIVITIES: 'Show archived activities',
  CLEAR: 'Clear',
  CHECKED: 'Checked',
  UNCHECKED: 'Unchecked',
  KEYWORD_SEARCH: 'Keyword Search',
  PRESS_ENTER_KEY_TO_GO_TO_THIS_PAGE: "Press 'Enter' key to go to this page.",
  MAIL_NOT_READ: 'mailnotread',
  MAIL_NOT_FILED: 'mailnotfiled',
  MAIL_NOT_FILED_ROUTE: 'mail-not-filed',
  MAIL_NOT_READ_ROUTE: 'mail-not-read',
  INQUIRIES_ACTIVITIES_ROUTE: 'inquiries-activities',
  ENTER: 'Enter',
  SELECT_A_COMMUNICATION: 'Select a Communication',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  UNABLE_TO_UPDATE_COLUMN_SETTINGS: 'Unable to update column settings',
  valueMustbe: (between, total) =>
    `Value must be between ${between} and ${total}`,
  applicationDetailsForInvoice: invcNumber =>
    `Application Detail For Invoice ${invcNumber}`,
  activityDetailsForInvoice: invcNumber =>
    `Activity Detail For Invoice ${invcNumber}`,
  dateShouldBeBetween: (minDate, maxDate) =>
    `Date should be between ${minDate} and ${maxDate}`,
  EXPORT_TO_EXCEL: 'Export To Excel',
  REFRESH: 'Refresh',
  SELECT_ALL: 'Select All',
  CLEAR_ALL: 'Clear All',
  BEST_FIT: 'Best Fit',
  VIEW_SETTINGS: 'View Settings',
  VIEW_SETTING: 'View Setting',
  ALERT: 'Alert',
  ALERTS: 'Alerts',
  EDIT_ALERT: 'Edit Alert',
  SCHEDULE_TASKS: 'Schedule Tasks',
  FREEZE_COLUMNS: 'Freeze columns',
  EDIT: 'Edit',
  REPORTS: 'Reports',
  MARK_ALL_SELECTED_AS_READ_UNREAD: 'Mark All Selected As Read/Unread',
  ASSIGN_ALL_SELECTED: 'Assign All Selected',
  DELETE_ALL_SELECTED: 'Delete All Selected',
  SAVE_VIEW_SETTINGS: 'Save View Settings',
  SETUP_ALERTS: 'Setup Alerts',
  UPDATE_SELECTED_RECORDS: 'Update Selected Records',
  NO_ATTACHMENTS_FOUND: 'No Attachments Found!',
  SEARCH_CUSTOMER_BY_EMAIL: 'Search Customers by Email',
  EMAIL_FROM: 'Email from',
  FOLLOWUP: 'Follow up',
  ISSUE_DESCRIPTION: 'Issue Description',
  OPTIONAL: 'Optional',
  FROM: 'From',
  TO: 'To',
  CC: 'Cc',
  BCC: 'Bcc',
  CREATE_EDIT: 'Create/Edit',
  CREATE: 'Create',
  SUBJECT: 'Subject',
  ISURGENT: 'Is Urgent?',
  ADD_CONTACT: 'Add Contact',
  NO_ACTIVITIES_FOUND: 'No Activities Found!',
  ACTIVITIES: 'Activities',
  VIEW_EMAIL: 'View Email',
  MESSAGE: 'Message',
  ATTACHMENTS: 'Attachments',
  NEW_ISSUE: 'New Issue',
  BEGIN_TYPING_CUSTOMER_NAME_OR_ID: 'Begin Typing Customer Name or ID ...',
  INVALID_CUSTOMER_ID: 'Invalid Customer ID',
  PAGE_SIZE: 'Page Size',
  GO_TO: 'Go to',
  ITEM: 'Item',
  ALL: 'All',
  INCLUDE_IN_EMAIL: 'Include In Email',
  INCLUDE_IN_EMAIL_MESSAGE: 'To include in emails, enter an email address',
  INCLUDE_IN_TEXT: 'Include In Text',
  INCLUDE_IN_VOICE: 'Include In Voice',
  KEEP_IN_SYNC_WITH_ERP: 'Keep In Sync With ERP',
  STATEMENT: 'Statement',
  KEEP_IN_SYNC: 'Keep In Sync',
  PRIMARY: 'Primary',
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  SAVE: 'Save',
  SAVE_NEXT: 'Save Next',
  CANCEL: 'Cancel',
  CONTACT_NAME: 'Contact Name',
  ADDRESSES: 'Addresses',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'Zip Code',
  COUNTRY: 'Country',
  PHONE: 'Phone',
  PHONE_EXT: 'Phone Ext',
  FAX: 'Fax',
  FAX_EXT: 'Fax Ext',
  EMAIL: 'Email',
  TITLE: 'Title',
  COMMENT: 'Comment',
  CUSTOMER_COMMUNICATION_DETAIL: 'Customer Communication Details',
  SAVE_CURRENT_SETTING_AS: 'Save current setting as',
  CLOSE: 'Close',
  SELECT: 'Select',
  VALUE: 'Value',
  ASSIGNED_USER: 'Assigned User',
  SELECT_USER_TO_ASSIGN: 'Select User to Assign',
  MARK_AS_COMPLETE: 'Mark as Complete',
  ARCHIVE_ISSUE: 'Archive issue',
  MARK_AS_READ_OR_UNREAD: 'Mark as Read Or Unread',
  MARK_AS_READ: 'Mark as Read',
  UPDATE: 'Update',
  INSERT: 'Insert',
  PROCESS_FOR_PREVIEW: 'Process for Preview',
  PROCESS_AND_EMAIL: 'Process and Email',
  PROCESS_AND_SAVE: 'Process and Save',
  PROCESS: 'Process',
  SHOW_SYSTEM_SETTING: 'Show system setting',
  PAGE_NOT_FOUND: 'Page not found!',
  ACCOUNT_DETAILS: 'Account Details',
  ACCOUNT_DETAILS_OR_NEW_ACTIVITY: 'Account Detail/New activity',
  ACCOUNT_DETAILS_NEW_TAB: 'Account Details New Tab',
  ACCOUNT_DETAILS_NEW_TAB_OR_NEW_ACTIVITY:
    'Account Details New Tab/New Activity',
  NEW_ACTIVITY: 'New Activity',
  SHOW_N_HOURS: str => `Show ${str} hours...`,
  UNAUTHORIZED_USER: 'Unauthorized user',
  PLEASE_GO_TO_MAIN_PAGE: 'Please go to main page',
  INFO: 'Info',
  ELLIPSE: '...',
  OF: 'of',
  ONLY_NUMBERS_ALLOWED: 'Only numbers allowed',
  FILTER: 'Filter',
  FILTERS: 'FILTERS',
  APPLY: 'Apply',
  SELECTION_REQUIRED: 'Selection Required',
  YOU_MUST_SELECT_ATLEAST_ONE_CUSTOMER:
    'You must select at least one customer.',
  CHECKBOX: 'Checkbox',
  LOADING: 'Loading ...',
  STATEMENT_OF_ACCOUNTS: 'Statement of Accounts',
  ACCOUNT_NOTES: 'Account Notes',
  ADD_A_NOTE: 'Add a Note',
  ADD_ACCOUNT_NOTE: 'Add Account Note',
  NOTE: 'Note',
  OK: 'OK',
  DELETE: 'Delete',
  SHOW_INVOICE_LINES: 'Show Invoice Lines',
  SHOW_ALL_ACCOUNTS: 'Show All Accounts',
  INCLUDE_PAST_DUE_PAYMENTS: 'Include Past Due Payments',
  PAYMENT: 'Payment',
  APPROVE: 'Approve',
  APPROVE_AND_PROCESS: 'Approve And Process',
  DECLINE: 'Decline',
  AUTO_PAYMENT_SCHEDULE: 'Auto-Payment Schedule',
  // TODO: waiting for API for usually pays
  USUALLY_PAYS: 'Usually pays',
  ON_TIME: 'On time',
  TOTAL_AMOUNT: 'Total Amount',
  TOTAL_BALANCE: 'Total Balance',
  TOTAL_AMOUNT_PREDICTED: 'TOTAL AMOUNT PREDICTED',
  ENABLE: 'Enable',
  ENABLED: 'Enabled',
  DISABLE: 'Disable',
  ISSUE: 'Issue',
  ISSUE_COMPLETE: 'Issue Complete',
  CUSTOMER_DOCS: 'Customer Docs',
  OPEN_CUSTOMER_DOCS: 'Open Customer Docs',
  CREATED: 'Created',
  ACTIVITY: 'Activity',
  ACTIVITY_FOR: 'Activity for',
  MARK_ACTIVITY_AS_COMPLETE: 'Mark Activity as Complete',
  ACTIVITY_TYPE: 'Activity Type',
  PROCEDURE: 'Procedure',
  TIME_STAMP: 'Time Stamp',
  TAGGED_AMT: 'Tagged Amt',
  VIEW_CONTACTS: 'View Contacts',
  SPOKE_WITH_CUSTOMER: 'Spoke with Customer',
  NOTES: 'Notes',
  TRANSACTIONS: 'Transactions',
  VOICE_MESSAGE: 'Voice Message',
  TEXT: 'Text',
  PDF: 'Pdf',
  CSV: 'Csv',
  XLS: 'Xls',
  XLSX: 'Xlsx',
  RTF: 'Rtf',
  BMP: 'Bmp',
  GIF: 'Gif',
  JPEG: 'Jpeg',
  PNG: 'Png',
  TIFF: 'Tiff',
  SELECT_CUSTOMER: 'Select Customer',
  SCHEDULE_FOLLOWUP: 'Schedule Follow Up',
  ASSIGN_TO: 'Assign to',
  USERS_TIME_ZONE: "User's Time Zone",
  FOLLOWUP_DATE: 'Follow Up Date',
  RESPONSE_DAYS: 'Response Days',
  RESPONSE_DATE: 'Response Date',
  DESCRIPTION: 'Description',
  SCHEDULE_DESCRIPTION: 'Schedule Description',
  SHOW: 'Show',
  SHOW_IN_TAB: 'Show in Tab',
  PRIORITY: 'Priority',
  SORT_ORDER: 'Sort Order',
  TAGGED_TO_ACTIVITY: 'Tagged To Activity',
  TAGGED_TO_ISSUE: 'Tagged To Issue',
  PAST_DUE: 'Past Due',
  OPEN: 'Open',
  EDIT_ALL_TAGGED_INVOICES: 'Edit All Tagged Invoices',
  HIDE_INVOICE_WITH_ZERO_BALANCE: 'Hide invoices with 0.00 balance',
  TEMPLATE: 'Template',
  EDIT_EMAIL_SIGNATURE: 'Edit Email Signature',
  NO_RECORDS_TO_DISPLAY: 'No records to display',
  TO_ATTACH_FILE_CLICK_BROWSE: 'To Attach File Click Browse',
  BROWSE: 'Browse',
  ATTACH_REPORT: 'Attach Report',
  ATTACH_TAGGED_INVOICES: 'Attach Tagged Invoices',
  REPORT_DEFAULT_OUTPUT: 'Report Default Output',
  SELECT_REPORT: 'Select Report',
  EXPECTED: 'Expected',
  PAYMENT_AMOUNT: 'Payment Amount',
  TRANSACTION_FEE: 'Transaction Fee',
  PAY_BY_DATE: 'Pay By Date',
  CUSTOMER_PROMISED: 'Customer Promised',
  ACTUAL: 'Actual',
  BROKEN: 'Broken',
  ACTUAL_AMOUNT: 'Actual Amount',
  ACTUAL_PAY_DATE: 'Actual Pay Date',
  No_TaggedInvoice_Found:
    'No tagged invoice found, please select tagged invoice',
  PLEASE_SELECT_TAGGED_INVOICE: 'Please select tagged invoice',
  CHECK_ITEMS_BELOW_TO_EXECUTE_THEM_FROM_EMAILS:
    'Check items below to exclude them from emails',
  usuallyPaysTooltipText: (
    years,
    yearLabel,
    avgDaystext,
    AvgDaysLateWeighted,
  ) =>
    `Based on ${years} ${yearLabel} of history with this account, they usually pay ${avgDaystext}. The average late days weighted by amount is ${AvgDaysLateWeighted} days.`,
  THIS_UPDATE_CANNOT_BE_UNDONE: 'This update cannot be undone',
  CONTINUE: 'Continue',
  PLEASE_SELECT_A_RECORD: 'Please select a record',
  PLEASE_SELECT_AN_INVOICE_TO_SHOW_THE_LINES_FOR:
    'Please select an invoice to show the lines for.',
  A_ROW_MUST_BE_SELECTED_TO_USE_THIS_OPTION:
    'A row must be selected to use this option.',
  SELECT_INVOICE: 'Select Invoice',
  PAYMENT_INFO: 'Payment Info',
  ADD_NEW_RECORD: 'Add New Record',
  SELECT_AN_OPTION: 'Select an option',
  APPLICATIONS: 'Applications',
  SELECT_ACTIVITY_TYPE: 'Select Activity Type',
  SELECT_USER: 'Select User',
  SELECT_EMAIL_TEMPLATE_TO_INSERT: 'Select email template to insert',
  OPTIONS: 'Options',
  REFRESH_LIST: 'Refresh List',
  VIEW_DETAILS: 'View Details',
  invoiceApplicationForPayment: invcNumber =>
    `Invoice Application For Payment ${invcNumber}`,
  INVOICE_DETAILS: 'Invoice Details',
  ADDITIONAL_PAYMENT: 'Additional Payment',
  TOTAL_OF_SELECTED_TRANSACTIONS: 'Total of Selected Transactions',
  SELECT_CURRENCY: 'Select Currency',
  DISCOUNT_AMOUNT: 'Discount Amount',
  EDIT_PAYMENT_METHODS: 'Edit Payment Methods',
  MAKE_A_PAYMENT: 'Make a Payment',
  PAYMENT_METHOD: 'Payment Method',
  DEFAULT_METHOD: 'Default Method',
  SELECT_PAYMENT_METHOD: 'Select Payment Method',
  companyWithATransactionCurrencyOf: currId =>
    `Company with a transaction currency of ${currId}`,
  CUST_ID: 'Cust ID',
  CUST_NAME: 'Cust Name',
  PO_NUMBER: 'PO Number',
  INVOICE_INFORMATION: 'Invoice Information',
  INVOICE_NUMBER: 'Invoice Number',
  AMOUNT: 'Amount',
  AMOUNT_HC: 'Amount HC',
  DUE_DATE: 'Due Date',
  PMT_DATE: 'Pmt Date',
  PMT_NOTE: 'Pmt Note',
  overrideInvoiceContact: num => `Override Invoice Contact ${num}`,
  CONSOLIDATED_INVC_NO: 'Consolidated Invc No',
  PREDICTED_PAY_DATE: 'Predicted Pay Date',
  PREDICTED_PAY_AMT: 'Predicted Pay Amt',
  PREDICTED_PAY_REASON: 'Predicted Pay Reason',
  INSTALLMENT_PLAN: 'Installment Plan',
  DISPUTE_REASON_INFORMATION: 'Dispute Reason Information',
  UDFs: 'UDFs',
  INVOICE_LINES: 'Invoice Lines',
  ADD_INSTALLMENT: 'Add installment',
  DELETE_ALL_INSTALLMENTS: 'Delete All Installments',
  INVOICE_DATE: 'Invoice Date',
  BALANCE_DUE: 'Balance Due',
  BALANCE_DUE_HC: 'Balance Due HC',
  DISC_DATE: 'Disc Date',
  DAYS_PAID_LATE: 'Days Paid Late',
  STATUS: 'Status',
  CLOSING_DATE: 'Closing Date',
  POST_DATE: 'Post Date',
  TAGGED_COMMUNICATIONS: 'Tagged Communications',
  DO_NOT_SYNC: 'DO NOT Sync',
  EXCLUDE_SYNC: 'Exclude from Sync',
  DELETE_INSTALLMENTS: 'Delete Installments',
  REASON: 'Reason',
  EXCLUDE_FROM_AGING: 'Exclude From Aging',
  SELECT_REASON: 'Select Reason',
  IN_DISPUTE: 'In Dispute',
  DISPUTE_DATE: 'Dispute Date',
  DISPUTE_AMOUNT: 'Dispute Amount',
  DISPUTE_RESOLVED: 'Dispute Resolved',
  RESOLVED_DATE: 'Resolved Date',
  INVOICE_UDF: 'Invoice UDF',
  DISPUTE_INVOICE_UDF: 'Dispute Invoice UDF',
  TOTAL: 'Total',
  PAYMENT_DETAILS: 'Payment Details',
  PAYMENT_INFORMATION: 'Payment Information',
  CHECK_REFERENCE: 'Check #/Reference',
  PAYMENT_DATE: 'Payment Date',
  TENDER_TYPE: 'Tender Type',
  UNAPPLIED_AMOUNT: 'Unapplied Amount',
  UNAPPLIED_AMOUNT_HC: 'Unapplied Amount HC',
  PAYMENT_UDF: 'Payment UDF',
  COMPANY_ID: 'Company Id',
  CUSTOMER_ID_AND_NAME: 'Customer ID & Name',
  AND: 'And',
  PAGE: 'Page',
  FETCHING_ACCOUNT_NOTES_FAILED: 'Fetching Account Notes Failed',
  DELETED_ACCOUNT_NOTES: 'Deleted Account Note',
  EXPORTING: 'Exporting',
  EXPORTED_SUCCESSFULLY: 'Exported Successfully',
  MAIL_NOT_READ_EXPORTED_TO_EXCEL: 'MailNotRead Exported To Excel',
  FAILED_TO_EXPORT: 'Failed to export',
  MAIL_NOT_FILED_EXPORTED_TO_EXCEL: 'MailNotFiled Exported To Excel',
  ALL_ACCOUNTS_EXPORTED_TO_EXCEL: 'All Accounts Exported To Excel',
  SCHEDULED_PAYMENTS_EXPORTED_TO_EXCEL: 'Scheduled Payments Exported To Excel',
  EXPECTED_PAYMENTS_EXPORTED_TO_EXCEL: 'Expected Payments Exported To Excel',
  REPORTING_GROUP_LOGS_EXPORTED_TO_EXCEL:
    'Reporting group Logs Exported To Excel',
  PROJECTED_CASH_RECEIPTS_EXPORTED_TO_EXCEL:
    'Projected cash receipts Exported To Excel',
  COMMUNICATIONS_BULK_UPDATE_COMPLETED: 'Communications Bulk Update Complete',
  COMMUNICATION: 'Communication',
  UPDATED: 'Updated',
  ASSOCIATE: 'Associate',
  YES: 'Yes',
  NO: 'No',
  DISPUTE_NOTE: 'Dispute Note',
  ADD_CONTACTS_TO_NOTE: 'Add Contacts To Note',
  SEARCH: 'Search',
  POSTAL_CODE: 'Postal Code',
  FEATURE_NOT_AVAILABLE_TITLE: 'Feature Not Available',
  SUCCESS: 'Success',
  ASSIGNING_EMAIL_FAILED: 'Assigning Email Failed',
  EMAIL_SUCCESSFULLY_ASSIGNED: 'Email Successfully Assigned',
  CREATE_MULTIPLE: 'Create Multiple',
  CREATE_SINGLE: 'Create Single',
  SELECT_REASON_CODE: 'Select Reason Code',
  THERE_ARE_NO_EMAILS: 'There are no emails',
  YEARS: 'Years',
  YEAR: 'Year',
  DAYS_LATE: 'Days late',
  DAYS_EARLY: 'Days Early',
  PERSONAL_CONTACTS: 'Personal Contacts',
  ADD_A_NEW_CONTACT: 'Add a New Contact',
  EMAIL_ADDR: 'Email Addr',
  PREVIEW: 'Preview',
  contactAdded: 'Contact Added Successfully',
  AddingContactFailed: 'Adding Contact Failed',
  TEMPLATE_ID: 'Template ID',
  CONTACT_ADDED_SUCCESSFULLY: 'Contact Added Successfully',
  CONTACT_UPDATED_SUCCESSFULLY: 'Contact Updated Successfully',
  ADDING_CONTACT_FAILED: 'Adding Contact Failed',
  CONTACT_DELETED: 'Contact Deleted',
  MAKE_A_CALL: 'Make a Call',
  INSERT_CODE_SNIPPET: 'Insert Code Snippet',
  CUSTOMER_LOGO: 'Logo',
  CUSTOMER_STATEMENT_LINK: 'Customer Statement Link',
  CUSTOMER_DOCUMENT_LINK: 'Customer Document Link',
  CUSTOMER_INVOICE_LINK: 'Customer Invoice Link',
  CUSTOMER_PAYMENTS_LINK: 'Customer Payments Link',
  CUSTOMER_CONTACTS_LINK: 'Customer Contacts Link',
  CUSTOMER_CONTACT_INFO_LINK: 'Customer Contact Info Link',
  CUSTOMER_STATEMENT_BUTTON: 'Customer Statement Button',
  CUSTOMER_DOCUMENT_BUTTON: 'Customer Document Button',
  CUSTOMER_INVOICE_BUTTON: 'Customer Invoice Button',
  CUSTOMER_PAYMENTS_BUTTON: 'Customer Payments Button',
  CUSTOMER_CONTACTS_BUTTON: 'Customer Contacts Button',
  CUSTOMER_CONTACTINFO_BUTTON: 'Customer ContactInfo Button',
  CUSTOMER: 'Customer',
  CUSTOMER_ACCOUNT_NUMBER: 'Customer Account Number',
  CUSTOMER_NAME: 'Customer Name',
  PRIMARY_CONTACT_NAME: 'Primary Contact Name',
  TOTAL_AGING: 'Total Aging',
  TOTAL_PAST_DUE: 'Total Past Due',
  USERNAME: 'Username',
  USER_ID: 'User ID',
  USER_NAME: 'User Name',
  USER_EMAIL: 'User Email',
  USER_ADDRESS_1: 'User Address 1',
  USER_ADDRESS_2: 'User Address 2',
  USER_ADDRESS_3: 'User Address 3',
  USER_ADDRESS_4: 'User Address 4',
  USER_ADDRESS_5: 'User Address 5',
  USER_CITY: 'User City',
  USER_STATE: 'User State',
  USER_POSTAL_CODE: 'User Postal Code',
  USER_COUNTRY: 'User Country',
  USER_PHONE: 'User Phone',
  USER_PHONE_EXT: 'User Phone Ext',
  USER_FAX: 'User Fax',
  USER_FAX_EXT: 'User Fax Ext',
  NA_AGE_CURRENT: 'National Account Current Aging',
  NA_AGE_FUTURE: 'National Account Future Aging',
  NA_AGE_1: 'National Account Age Bucket 1',
  NA_AGE_2: 'National Account Age Bucket 2',
  NA_AGE_3: 'National Account Age Bucket 3',
  NA_AGE_4: 'National Account Age Bucket 4',
  NA_LATE_AGE_CURRENT: 'National Account Due Date Current Aging',
  NA_LATE_AGE_FUTURE: 'National Account Due Date Future Aging',
  NA_LATE_AGE_1: 'National Account Due Date Age Bucket 1',
  NA_LATE_AGE_2: 'National Account Due Date Age Bucket 2',
  NA_LATE_AGE_3: 'National Account Due Date Age Bucket 3',
  NA_LATE_AGE_4: 'National Account Due Date Age Bucket 4',
  NA_TOTAL_AGE: 'National Account Total Aging',
  NA_CREDIT_LIMIT: 'National Account Credit Limit',
  NA_AVAILABLE_CREDIT: 'National Account Available Credit',
  TRAN_ID: 'Transaction ID',
  TRAN_NO: 'Transaction No',
  TRAN_CURR_ID: 'Trans Currency ID',
  CURR_ID: 'Currency ID',
  TAGGED_INVC_TOTAL: 'Tagged Invoice Total',
  TAGGED_HC_INVC_TOTAL: 'Tagged Invoice Total HC',
  TAGGED_INVC_BALANCE: 'Tagged Invoice Balance',
  TAGGED_HD_INVC_BALANCE: 'Tagged Invoice Balance HC',
  NONE: 'None',
  CUST_SELECT: 'CustSelect',
  DISPUTE: 'Dispute',
  NO_CUST_SELECT: 'NoCustSelect',
  REPLY: 'Reply',
  REPLY_ALL: 'Reply All',
  MARK_AS_UNREAD: 'Mark as unread',
  EMAIL_IS_REQUIRED: 'Email is required.',
  SEARCH_CONTACT_BY_EMAIL: 'Search Contact By Email.',
  PERSONAL: 'Personal',
  ACTIVITY_ID: 'Activity ID',
  ACTIVITY_DESCRIPTION: 'Activity Description',
  FORWARD: 'Forward',
  FOR: 'For',
  COPY_NOTES_EMAIL: 'Copy Notes/Email',
  REASSIGN: 'Reassign',
  REASSIGN_CUSTOMER: 'Reassign Customer',
  ISSUE_DESC_IS_REQUIRED: 'Issue Description is required.',
  ISSUE_RESOLVED: 'Issue Resolved',
  REOPEN: 'Reopen',
  CLOSE_PRIOR_ACTIVITY: 'Close prior Activity',
  SAVE_AND_SEND: 'Save and Send',
  ERROR_SENDING: 'Error Sending',
  RECEIVED_MAIL: 'Received Mail',
  EMAIL_QUEUED: 'Email Queued',
  SENT_MAIL: 'Sent Mail',
  RESEND: 'Resend',
  FOLLOW_UP_MARK_AS_COMPLETE: 'Follow Up marked as complete',
  NOTICE: 'Notice',
  THERE_ARE_NO_TEXTS: 'There are no texts',
  THERE_ARE_NO_VOICE_MESSAGES: 'There are no voice messages',
  SAVE_AND_NEXT_ITEM: 'Save and Next Item',
  COMPANY: 'Company',
  COPY_ATTACHMENTS_TO_CUST_DOCS: 'Copy Attachments to Cust Docs',
  COPY: 'Copy',
  DESELECT: 'Deselect',
  CHOOSE_FOLDER_TO_COPY_TO: 'Choose Folder to Copy to',
  CHOOSE_DOCUMENTS_TO_ATTACH: 'Choose Documents to Attach',
  RENAME: 'Rename',
  NEW_FOLDER: 'New Folder',
  UPLOAD: 'Upload',
  ATTACH_TO_COMMUNICATE: 'Attach to Communicate',
  INVOICE: 'Invoice',
  ALREADY_EXISTS: 'already Exists.',
  COPIED_SUCCESSFULLY: 'copied Successfully',
  ACTIVITIES_EXPORTED_TO_EXCEL: 'Activities Exported To Excel',
  STATEMENTS_OF_ACCOUNTS_EXPORTED_TO_EXCEL: 'Statements of Exported To Excel',
  CLOSED: 'Closed',
  DISPUTE_CODE_DATE: 'Dispute Code Date',
  CLOSING_DATE_CANNOT_BE_LESS_THAN_POST_DATE:
    'Closing Date cannot be less than Post Date.',
  defaultIssueDescription: (userName, customerName) =>
    `User ${userName} created an account note for customer ${customerName}.`,
  DIRECTORY_CAN_NOT_BE_ADDED: 'Directory cannot be added',
  THE_FILE_ALREADY_EXISTS_IN_THE_DIRECTORY:
    'The file already exists in the directory.',
  EXCEL_WARNING_MESSAGE: 'Maximum of 5000 records will be downloaded',
  ENTER_THE_NEW_FOLDER_NAME: 'Enter the new folder name',
  TEXT_QUEUED: 'Text Queued',
  SUCCESSFUL_TEXT: 'Successful Text',
  UNSUCCESSFUL_TEXT: 'Unsuccessful Text',
  UNSUCCESSFUL_VOICE_CALL: 'Unsuccessful Voice Call',
  VOICE_MESSAGE_QUEUED: 'Voice Message Queued',
  VOICE_CALL_QUEUED: 'Voice Call Queued',
  SUCCESSFUL_PHONE_CALL: 'Successful Phone Call',
  FILE_NAME: 'File Name',
  SIZE: 'Size',
  PAYMENT_METHOD_DELETED_SUCCESSFULLY: 'Payment method deleted successfully.',
  REMAINDER_FOR_TRANSACTION_FEE:
    'THERE MAY BE A TRANSACTION FEE APPLIED TO COVER CREDIT CARD PROCESSING COSTS.',
  REMINDER: 'Reminder',
  MAKE_PAYMENT_INFO: 'Are you sure you wish to make this payment',
  PAYMENT_AMOUNT_CAN_NOT_BE_ZERO: 'Payment amount cannot be 0.0',
  PAYMENT_METHOD_IS_REQUIRED: 'Payment method is required',
  PAYMENT_METHOD_NOT_FOUND: 'Payment method not found',
  YOU_CAN_NOT_MAKE_A_PAYMENT_AGAINST_A_CR:
    'Some invoices may not be eligible because: You can not make a payment against a CR.',
  NO_CURRENCY_HAS_BEEN_SELECTED: 'No currency has been selected.',
  PAYMENT_MULTIPLE_CURRENCY_ERROR:
    'You cannot make a payment because you have multiple currencies selected.',
  CREDIT_MEMO_MESSAGE:
    'We noticed you selected a credit memo for this payment.',
  AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Amount must be greater than zero',
  YOUR_AMOUNT_DO_NOT_TOTAL_THE_INVOICE_AMOUNT:
    'Your amounts do not total the invoice amount',
  ERROR: 'Error',
  COLLAPSE_EXPAND_THE_LEFT_PANE: 'Collapse/Expand the left pane',
  NOT_ENOUGH_REGION_OR_COUNTRY_INFORMATION:
    'Not enough region or country information',
  SOLU_PAY_JWT: 'SoluPayJWT',
  ENABLE_ENHANCED_ADMIN: 'EnableEnhancedAdmin',
  DATE_TIME_FORMAT: 'M/DD/YYYY h:mm:ss A',
  DATE_TIME_FORMAT2: 'MM-DD-YYYY hh:mm A',
  DATE_FORMAT: 'M/DD/YYYY',
  DATE_FORMAT_1: 'M/D/YYYY',
  DATE_FORMAT_2: 'MM/DD/YYYY',
  SETTINGS: 'Settings',
  LOGOUT: 'Logout',
  OVERVIEW: 'OVERVIEW',
  CONTACTS: 'CONTACTS',
  DOCUMENTS: 'DOCUMENTS',
  PLEASE_WAIT_WHILE_WE_CONNECT_YOU_TO_AN_AGENT:
    'Please wait while we connect you to an agent...',
  WARNING: 'Warning',
  INFORMATION: 'Information',
  NOTE_FIELD_IS_REQUIRED_FIELD: 'Note field is required',
  PLEASE_SELECT_RECORDS_TO_ADD_CONTACT_TO_NOTES:
    'Please select records to add contact to notes',
  VIOLATION_OF_UNIQUE_KEY: 'Violation of UNIQUE KEY',
  NOTES_TAB: 'notes',
  TRANSACTIONS_TAB: 'transactions',
  VOICE_MESSAGE_TAB: 'voiceMessage',
  EMAIL_TAB: 'email',
  ATTACHMENTS_TAB: 'attachments',
  PAYMENTS_TAB: 'payment',
  TEXT_TAB: 'text',
  FILE_NOT_FOUND: 'File not Found',
  INQUIRY_ACCOUNT: 'inquiryAccount',
  ENABLE_PREVIEW_FEATURE: 'Enable Preview Features',
  FILE_ALREADY_EXISTS_ON_UPLOADING_FILE:
    'File already exists with the same name. Please rename with a different name.',
  ADDED_TO_FAVORITE: 'Added to favorite',
  ADDING_TO_FAVORITE_FAILED: 'Adding to favorite failed',
  ADD_TO_FAVORITE: 'Add to favorite',
  CREDIT: 'Credit',
  HISTORY: 'History',
  ROLLED_UP_HISTORY: 'Rolled Up History',
  NACM: 'NACM',
  PORTAL_ACCESS: 'Portal Access',
  DATE_ESTABLISHED: 'Date Established',
  CREDIT_CLASS: 'Credit Class',
  COST_OF_CREDIT: 'Cost Of Credit',
  CURRENCY: 'Currency',
  SALES_TERRITORY: 'Sales Territory',
  HIGHEST_BALANCE: 'Highest Balance',
  TERMS: 'Terms',
  DAYS_PAST_DUE: 'Days Past Due',
  CREDIT_LIMIT: 'Credit Limit',
  AVAILABLE_CREDIT: 'Available Credit',
  ALLOW_ONLINE_PMT: 'Allow Online Pmt',
  OLDEST_DUE_DATE: 'Oldest Due Date',
  CREDIT_HOLD: 'Credit Hold',
  DO_NOT_SHARE_WITH_CREDIT_BUREAU: 'Do Not Share With Credit Bureau',
  CREDIT_REVIEW_DATE: 'Credit Review Date',
  CREDIT_SCORE: 'Credit Score',
  SCORE: 'Score',
  VARIABLE: 'Variable',
  ASSIGNED_WEIGHT: 'Assigned Weight',
  WEIGHTED_WEIGHT: 'Weighted Weight',
  LOCKSTEP_CREDIT_SCORE: 'Lockstep Credit Score',
  EFT_ONLY: 'EFT only',
  CREDIT_CARD_ACH: 'Credit Card/EFT',
  CREDIT_CARD_ONLY: 'Credit Card Only',
  EFT: 'EFT',
  ACH: 'ACH',
  VERY_POOR: 'Very Poor',
  POOR: 'Poor',
  FAIRISH: 'Fairish',
  GOOD: 'Good',
  VERY_GOOD: 'Very Good',
  CALCULATED_LOCKSTEP_CREDIT_SCORE_TEXT:
    'Below is a summary of how the Lockstep credit score is calculated for this account.',
  DETAIL_CREDIT_SCORE_LINK: 'See detailed credit score rules >',
  SALES_PERSON: 'Sales Person',
  CREDIT_REP: 'Credit Rep',
  INVOICE_FORM: 'Invoice Form',
  DAYS_TO_RESPOND: 'Days to Respond',
  PARENT_ID: 'Parent ID',
  CUSTOMER_CLASS: 'Customer Class',
  USER_DEFINED_FIELDS: 'User Defined Fields',
  LATE_DAYS_OVERALL: 'Late Days Overall *',
  LATE_DAYS_WEIGHTED_BY_AMOUNT: 'Late Days Weighted by Amount **',
  DIFFERENCE: 'Difference',
  TOTAL_INVOICE: 'Total Invoice',
  DUE_FUTURE: 'Due Future',
  DUE_NOW: 'Due Now',
  LATE_31_TO_60_DAYS: 'Late 31-60 Days',
  LATE_61_TO_90_DAYS: 'Late 61-90 Days',
  LATE_91_TO_120_DAYS: 'Late 91-120 Days',
  LATE_121_PLUS_DAYS: 'Late 121+ Days',
  AVERAGE_DAYS_LATE: 'Average days late',
  BROKEN_PROMISE: 'Broken Promises',
  DISPUTES: 'Disputes',
  NINETY_DAYS: 'Ninety Days',
  SIX_MONTHS: 'Six Months',
  ONE_YEAR: 'One Year',
  TWO_YEAR: 'Two Year',
  AGING_BY_DUE_DATE: 'Aging By Due Date',
  LOCKSTEP_CREDIT_SCORE_SCALE: 'Lockstep Credit Score Scale',
  LABEL: 'label',
  INVOICE_EN: 'Invoice - EN',
  INVOICE_URL: 'Invoice - URL',
  NACM_SCORE_DATE: 'NACM Score Date',
  NACM_SCORE: 'NACM Score',
  NACM_RISK_CLASS: 'NACM Risk Class',
  NACM_RISK_DESC: 'NACM Risk Desc',
  NACM_SCORE_FACTORS: 'NACM Score Factors',
  addressLine: val => `Address Line ${val}`,
  SELECT_AN_INVOICE_FORM: 'Select an Invoice Form',
  NAME: 'Name',
  PRINT_AS: 'Print As',
  ADDR: 'Addr',
  IS_INACTIVE: 'Is Inactive',
  ZIP: 'Zip',
  INSERT_DATE: 'Insert Date',
  UPDATE_DATE: 'Update Date',
  ADD: 'ADD',
  UDF: 'UDF',
  EMAIL_ADDR_IS_REQUIRED: 'Email Addr is Required',
  CONTACT_NAME_IS_REQUIRED: 'Contact Name is Required',
  INVOICE_FILE: 'InvoiceFile.pdf',
  CANNOT_DELETE_ACTIVE_CONTACT: 'Can not delete active contact',
  DELETE_THIS_CONTACT: 'Delete this contact',
  CREDIT_SAFE: 'Credit Safe',
  SCORE_DATE: 'Score Date',
  LOCAL_SCORE: 'Local Score',
  LOCAL_SCORE_DESC: 'Local Score Desc',
  INTERNATIONAL_SCORE: 'International Score',
  INTERNATIONAL_SCORE_DESC: 'International Score Desc',
  VIOLATION_OF_UNIQUE_KEY_CONSTRAINT: 'Violation of UNIQUE KEY constraint',
  duplicateContact: contactName =>
    `You are trying to add duplicate contact name i.e. "${contactName}", Please use different Name.`,
  DUPLICATE_VALUE_FOUND: 'Duplicate Value Found',
  ARE_YOU_SURE: 'Are you sure',
  DELETING_CONTACT_FAILED: 'Deleting contact failed',
  FAILED_TO_DOWNLOAD_INVOICE: 'Failed to Download Invoice',
  NO_DATA_TO_GENERATE_REPORT: 'No Data to generate report',
  MANUAL_PDF_UPLOAD: 'Manual upload pdf',
  FILE_UPLOADING_FAILED: 'File uploading failed',
  FILE_UPLOADED_SUCCESSFULLY: 'File uploaded successfully',
  VIEW_MESSAGES: 'View Messages',
  MESSAGES_EXPORTED_TO_EXCEL: 'Messages Exported to Excel',
  SHOW_ALL_MESSAGES: 'Show All Messages',
  MARK_ALL_AS_READ: 'Mark All As Read',
  FAILED_TO_DOWNLOAD_ATTACHMENT: 'Failed to download attachment',
  FAILED_TO_UPDATE_THE_MAIL_STATUS: 'Failed to update Read/Unread status',
  INCLUDE_CUSTOMERS_WITH_ZERO_BALANCE: 'Include customers with zero balance',
  INBOX: 'Inbox',
  PDF_DOES_NOT_EXISTS_FOR_THIS_INVOICE:
    'The PDF does not exist for this invoice. The file was either not available to be uploaded or your parameters are not set to sync.',
  CONNECT_TO_CUSTOMER: 'ConnectToCustomer',
  BULK_FILE_UPLOAD: 'Bulk File Upload',
  DOWNLOAD_TEMPLATE: 'Download Template',
  LOGOUT_WARNING_MESSAGE_TWO:
    'Please click the button below if you are still using the application',
  LOGOUT_WARNING_BUTTON: 'Stay Logged In',
  logoutWarningMessageOne: remainingTime =>
    `You will be logged out in ${remainingTime} minutes due to
    inactivity`,
  // ERROR_PAGE_MESSAGE:
  //   'We apologize for any inconvenience you may have experienced. Our technical team will investigate the issue as high priority. In the meantime, would you mind sharing some additional details with us?',
  ERROR_PAGE_MESSAGE:
    'We apologize for any inconvenience you may have experienced. Our technical team will investigate the issue as high priority.',
  TRY_AGAIN_TEXT: 'Try Again',
  SHARE_INFO: 'Share Info',
  PAYMENTS_EXPORTED_TO_EXCEL: 'Payments Exported to Excel',
  PAYMENTS: 'Payments',
  FAILED_TO_LOAD_DATA: 'Failed to load data',
  LOADING_PAYMENTS_FAILED: 'Loading payments failed',
  TOTAL_UNAPPLIED: 'Total Unapplied',
  INVOICES_EXPORTED_TO_EXCEL: 'Invoices Exported to Excel',
  CONTACTS_EXPORTED_TO_EXCEL: 'Contacts Exported to Excel',
  INVOICES: 'Invoices',
  LOADING_INVOICES_FAILED: 'Loading invoices failed',
  FETCHING_CONTACTS_FAILED: 'Failed to fetch contacts',
  FOLDER_NOT_SELECTED: 'Folder not Selected',
  EMPTY_GUID_VALUE: '00000000-0000-0000-0000-000000000000',
  REJECT: 'Reject',
  NOTIFICATION: 'Notification',
  DUE_PAYMENTS_NOTIFICATION:
    'Some payments are not due today so they will be processed on their due date.',
  APPROVEANDPROCESS: 'approveandprocess',
  TIME_ZONE: 'Time Zone',
  NEXT_RUN_DATE: 'Next Run Date',
  START_DATE: 'Start Date',
  LAST_RUN_DATE: 'Last Run Date',
  RUN: 'Run',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  Every_1_Hour: 'Every 1 Hour',
  Every_2_Hour: 'Every 2 Hour',
  Every_3_Hour: 'Every 3 Hour',
  Every_4_Hour: 'Every 4 Hour',
  Every_6_Hour: 'Every 6 Hour',
  Every_8_Hour: 'Every 8 Hour',
  Every_12_Hour: 'Every 12 Hour',
  Every_24_Hour: 'Every 24 Hour',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  ALLOW_DISCOUNT: 'AllowDiscounts',
  SUMMARY: 'Summary',
  DETAILS: 'Details',
  PURGE: 'Purge',
  UPDATE_SELECTED: 'Update Selected',
  IMPORT_PAYMENTS: 'Import Payments',
  RESEND_PAYMENTS: 'Resend Payments',
  CLEAR_FAILED_STATUS: 'Clear Failed Status',
  DECLINE_PAYMENT: 'Decline Payment',
  DISCOUNT_ERROR:
    "Please note that a discount cannot be applied as you've selected a credit memo for this payment.",
  PENDING_PAYMENTS_DETAILS_EXPORTED_TO_EXCEL:
    'Pending payments exported to excel',
  CONTACT_ALREADY_EXISTS: 'Contact already exists',
  PURGE_DECLINED: 'Purge Declined',
  PURGE_NO_RESPONSE: 'Purge No Response',
  PURGE_RECONCILED: 'Purge Reconciled',
  RUN_PURGE: 'Run Purge',
  MAX_PURGE_DAYS: 70368744177664,
  PROFILE: 'Profile',
  MESSAGES: 'Messages',
  ASSIGN_MESSAGES: 'Assign Messages',
  CM_NOT_ALLOWED_FROM_COMPANY_SETTING_MESSAGE:
    'We noticed you selected a credit memo for this payment. Payments on credit memos cannot be made, so that selection is not shown here.',
  RESEND_PAYMENTS_WARNING:
    "Proceeding will send all pending payments with a webhook status of 'Not Tried' and 'Failure'. Ok to continue?",
  CLEAR_WEBHOOK_STATUS:
    "Proceeding will reset all pending payments with a webhook status of 'Failure' to 'Not tried'. Ok to continue?",
  FAV_SUMMARY: 'favsummary',
  FAV_DETAILS: 'favdetails',
  FAV_PURGE: 'favpurge',
  PENDING_PAYMENTS_SUMMARY_TAB: 0,
  PENDING_PAYMENTS_DETAILS_TAB: 1,
  PENDING_PAYMENTS_PURGE_TAB: 2,
  BLANK_ISSUE: '[blank issue]',
  YOU_WILL_BE_ALERTED_WHEN_PROCESS_IS_COMPLETE:
    'You will be alerted when process is complete.',
  // Todo: Add Dialog in All Accounts Page for Reports
  processEmailForReportTypeAccount: selectedRowsCount =>
    `You have selected ${selectedRowsCount} customer(s) to email the selected report to.  Once completed, this cannot be undone. Continue?`,
  YOU_MUST_SELECT_A_REPORT_TYPE: 'You must select a report type.',
  FAILED_TO_PROCESS_REPORT: 'Failed to process report',
  SENT_ITEMS: 'Sent Items',
  TAGGED_INVOICES: 'Tagged Invoices',
  REFRESH_LIST_DETAILS: 'Refresh List Details',
  LOADING_BEST_ACTIVITIES_FAILED: 'Loading Best Activities failed',
  ACTIVITIES_COMPLETED_THIS_MONTH: 'Activities completed this month',
  COLLECTED_THIS_MONTH: 'Collected this month',
  OPEN_INVOICES: 'Open Invoices',
  PAST_DUE_INVOICES: 'Past Due Invoices',
  LOADING_HOME_DASHBOARD_STATS_FAILED: 'Loading Home Dashboard Stats failed',
  LOADING_USER_ALERT_SUMMARY_FAILED: 'Loading User Alert Summary failed',
  LOADING_LAST_SYNC_DATA_FAILED: 'Loading Last Sync Data failed',
  COLLECTIONS_SUCCESS_FOR: 'Collections Success for',
  NEXT_BEST_ACTIVITY_TOOLTIP:
    'Next Best Activity consolidates all your assigned activities into one list. The activities are the same as in the individual “Activities” menu items. The list is sorted based on priority set by the credit class.',
  NEXT_BEST_ACTIVITY: 'Next Best Activity',
  SYNC_WAS_SUCCESSFULLY: 'Sync was Successful',
  SYNC_HAD_ERRORS: 'Sync had Errors',
  SYNC_STARTED: 'Sync Started',
  LAST_SYNC_OCCURRED: 'Last Sync Occurred :',
  ACTIVITIES_BY_USERS: 'Activities By Users',
  ACTIVITY_DETAIL: 'Activity Detail',
  SAVE_AS_DRAFT: 'Save as Draft',
  DELETE_ACTIVITY_SUCCESSFUL: 'Activity deleted successfully',
  PLEASE_ENTER_VALID_DUE_DATE: 'Please enter valid due date',
  PLEASE_ENTER_VALID_DATE: 'Please enter valid date',
  PROJECTED_PAYMENT_DAYS: 2147483647,
  SUPPORT_FAQ: 'Support/FAQ',
  PLEASE_SAVE_THE_ROWS_BEFORE_SAVING_THE_INSTALLMENTS:
    'Please save the rows before saving the installments',
  CASH_COLLECTED: 'Cash Collected :',
  ACTIVITY_COMPLETE: 'Activity Complete :',
  NEW_ALERT_MESSAGE_NOTIFICATION: 'Received new alert message, please check',
  LOGIN_ERROR: 'Your login attempt was not successful. Please try again.',
  SETTING_NAME_IS_REQUIRED: 'Setting name is required',
  LEADING_TILDE_RESERVED_FOR_SYSTEM_SETTING_NAMES:
    'leading tilde (~) reserved for system setting names',
  LEADING_TILDE_RESERVED_FOR_ALERT_NAMES:
    'leading caret (^) reserved for alert names',
  PLEASE_SAVE_THE_ROWS_BEFORE_SAVING_THE_SETTINGS:
    'Please save the rows before saving the settings',
  FAILED_TO_LOAD_VIEW_SETTINGS: 'Failed to load view settings',
  SHOW_SYSTEM_SETTINGS: 'Show System Settings',
  INCLUDE_ALL_USERS: 'Include All Users',
  SAVE_CURRENT_SETTINGS_AS: 'Save current settings as',
  COPY_VIEW_SETTINGS: 'Copy View Settings',
  deleteViewSettingConfirm: (setting, type = 'view setting') =>
    `Are you sure you want to delete the ${type} "${setting}"?`,
  updateViewSettingConfirm: setting =>
    `Are you sure you want to update view setting "${setting}"?`,
  VIEW_COLUMN_SETTINGS: 'View Column Settings',
  CHOOSE_USERS_TO_COPY_SETTINGS_TO: 'Choose users to copy settings to',
  FAILED_TO_LOAD_USERS: 'Failed to load users',
  COPIED_VIEW_SETTINGS_SUCCESSFULLY: 'Copied view settings successfully',
  FAILED_TO_COPY_VIEW_SETTINGS: 'Failed to copy view settings',
  COLUMN_SETTINGS_UPDATED_SUCCESSFULLY: 'Column settings updated successfully',
  VIEW_SETTINGS_DELETED_SUCCESSFULLY: 'View settings deleted successfully',
  deleteSuccessfully: type => `${type} deleted successfully`,
  FAILED_TO_DELETE_VIEW_SETTINGS: 'Failed to delete view settings',
  failedToDelete: type => `Failed to delete ${type}`,
  ERROR_WHILE_FETCHING_REPORTS_BUTTON_VISIBILITY:
    'Error while fetching reports button visibility',
  INQUIRY_VIEW: 'Inquiry View',
  NEW_BULK_ACTIVITY: 'New Bulk Activity',
  PAYMENT_AMOUNT_FIELD: 'paymentAmount',
  ACTUAL_PAYMENT_AMOUNT_FIELD: 'actualPaymentAmount',
  TAGGED_FIELD: 'tagged',
  ALERT_UPDATE_MESSAGE: 'Failed to add/update alerts schedule',
  HOMEPAGE: 'homepage',
  THE_UDF_NAME_CANNOT_BE_CHANGED_HERE:
    'The UDF name cannot be changed here. Please change it in the site options.',
};

export const sideNavItemTextSource = {
  Sidenav_accounts_Text: 'Accounts',
  Sidenav_actionsummary_Text: 'Activity Summary',
  Sidenav_activitymaint_Text: 'Activity Types',
  Sidenav_admin_Text: 'Admin',
  Sidenav_Advanced_Text: 'Advanced',
  Sidenav_agecustomers_Text: 'Age Accounts',
  Sidenav_alertcodemaint_Text: 'Activity Templates',
  Sidenav_allaccounts_Text: 'All accounts',
  Sidenav_communication_Text: 'Activities',
  Sidenav_companymaint_Text: 'Company',
  Sidenav_contact_Text: 'Contact Info',
  Sidenav_contacts_Text: 'Contacts',
  Sidenav_countrymaint_Text: 'Country',
  Sidenav_creditclassmaint_Text: 'Credit Class',
  Sidenav_creditinquiry_Text: 'Credit Inquiry',
  Sidenav_custmaint_Text: 'Customer',
  Sidenav_customermessage_Text: 'Customer Message',
  Sidenav_dashboard_Text: 'Dashboard',
  Sidenav_disputes_Text: 'Disputes',
  Sidenav_emailtemplatemaint_Text: 'Communication Template',
  Sidenav_entitymassupdate_Text: 'Mass Update',
  Sidenav_errorsendingemail_Text: 'Error Sending Email',
  Sidenav_expectedpayment_Text: 'Expected Payments',
  Sidenav_expectedpaymentpromisebroken_Text: 'Expected Payment Promise Broken',
  Sidenav_favorites_Text: 'Favorites',
  Sidenav_followup_Text: 'Follow-Up',
  Sidenav_followupduetoday_Text: 'Followup Due Today',
  Sidenav_followuppastdue_Text: 'Followup Past Due',
  Sidenav_generateactions_Text: 'Generate Actions',
  Sidenav_home_Text: 'Home',
  Sidenav_importcustnotes_Text: 'Import',
  Sidenav_inquiries_Text: 'Inquiries',
  Sidenav_inquiryAccount_Text: 'By Account',
  Sidenav_inquiryuser_Text: 'By User',
  Sidenav_internalmessage_Text: 'Internal Message',
  Sidenav_invoices_Text: 'Invoices',
  Sidenav_logs_Text: 'Logs',
  Sidenav_mail_Text: 'Mail',
  Sidenav_mailnotfiled_Text: 'Mail Not Filed',
  Sidenav_mailnotread_Text: 'Mail Not Read',
  Sidenav_mailretrieval_Text: 'Mail Retrieval',
  Sidenav_masterfiles_Text: 'Master Files',
  Sidenav_messages_Text: 'Messages',
  Sidenav_OnlinePmt_Text: 'Pending Payment',
  Sidenav_openissuewithoutopenactivity_Text: 'Open Issue without Open Activity',
  Sidenav_payments_Text: 'Payments',
  Sidenav_pendingpaymentdeclinedduringlast7days_Text: `Pending Payment Declined During Last 7 Days`,
  Sidenav_pendingpaymentnotreconciled_Text: 'Pending Payment NOT Reconciled',
  Sidenav_portalmessages_Text: 'Messages',
  Sidenav_promisetopays_Text: 'Promise to pays',
  Sidenav_reasoncodemaint_Text: 'Reason Code',
  Sidenav_reportinggrouplogs_Text: 'Reporting Group Logs',
  Sidenav_reportinggroups_Text: 'Reporting Groups',
  Sidenav_reportmaint_Text: 'Report',
  Sidenav_salesrepmaint_Text: 'Sales Rep',
  Sidenav_scheduledpayments_Text: 'Scheduled Payments',
  Sidenav_shortpays_Text: 'Short pays',
  Sidenav_sitemaintenance_Text: 'Settings & Utilities',
  Sidenav_siteoptions_Text: 'Site Options',
  Sidenav_standard_Text: 'Standard',
  Sidenav_statement_Text: 'Statement',
  Sidenav_syncsoftware_Text: 'Sync Setup',
  Sidenav_tasks_Text: 'Activities',
  Sidenav_Activities_Text: 'Activities',
  Sidenav_useractionmaint_Text: 'Message Settings',
  Sidenav_usercashprojdetail_Text: 'Projected Cash Receipts',
  Sidenav_usercontacts_Text: 'Contacts',
  Sidenav_userinvoices_Text: 'Invoices',
  Sidenav_usermaint_Text: 'Users',
  Sidenav_userpayments_Text: 'Payments',
  Sidenav_user_Text: 'Users',
  Sidenav_UserAction_Text: 'Message Settings',
  Sidenav_Activity_Text: 'Activity Types',
  Sidenav_ActionCode_Text: 'Activity Templates',
  Sidenav_dbutilitysetup_Text: 'DB utility setup',
};

export const textEditorToolbarTitle = {
  FONT_FAMILY: 'Font Family',
  Header: 'Header',
  FONT_SIZE: 'Font Size',
  BOLD: 'Bold',
  ITALIC: 'Italic',
  UNDERLINE: 'Underline',
  STRIKE_THROUGH: 'Strike Through',
  TEXT_ALIGNMENT: 'Text Alignment',
  TEXT_DIRECTION: 'Text Direction',
  TEXT_COLOR: 'Text Color',
  BACKGROUND_COLOR: 'Background Color',
  SUBSCRIPT: 'Subscript',
  SUPERSCRIPT: 'Superscript',
  HEADING_1: 'Heading 1',
  HEADING_2: 'Heading 2',
  BLOCKQUOTE: 'Blockquote',
  INSERT_LINK: 'Insert Link',
  INSERT_IMAGE: 'Insert Image',
  ORDERED_LIST: 'Ordered List',
  BULLET_LIST: 'Bullet List',
  DECREASE_INDENTATION: 'Decrease Indentation',
  INCREASE_INDENTATION: 'Increase Indentation',
  CLEAN_FORMATTING: 'Clean Formatting',
  INSERT_CODE: 'Insert Code',
  INSERT_DATE_AND_TIME: 'Insert Date and Time',
};

export const fileNames = {
  CustomerMessage: 'CustomerMessage',
  ErrorSendMailAlert: 'ErrorSendingEmail',
  InternalMessage: 'InternalMessage',
  OrphanIssue: 'OpenIssueWithoutOpenActivity',
  RuleAlert: 'RuleAlert',
  MailNotRead: 'MailNotRead',
  AllAccounts: 'AllAccounts',
  UnreadMailAlert: 'MailNotRead',
};

export const gridModeTypes = {
  CustomerMessage: 'CustomerMessageActivities',
  ErrorSendMailAlert: 'ErrorSendingEmailActivities',
  InternalMessage: 'InternalMessageActivities',
  OrphanIssue: 'OrphanIssueActivities',
  UnreadMailAlert: 'MailNotReadActivities',
  RuleAlert: 'RuleAlertActivities',
  None: 'None',
  ActivitiesForCustomerAndUser: 'ActivitiesForCustomerAndUser',
  StatementOfAccount: 'StatementOfAccount',
  AllAccounts: 'AllAccounts',
  ActivityUserStandardAlert: 'ActivityUserStandardAlert',
  ActivityUserAlertSummary: 'ActivityUserAlertSummary',
  ExpectedPayments: 'ExpectedPayments',
  UserPaymentHistory: 'UserPaymentHistory',
  ProjectedCashReceipts: 'ProjectedCashReceipts',
  ReportingGroupLog: 'ReportingGroupLog',
  ScheduledPayments: 'ScheduledPayments',
  Contacts: 'Contacts',
  UserInvoices: 'UserInvoices',
  DraftActivity: 'DraftActivity',
  followupActivities: 'followupActivities',
  MailNotFiledActivities: 'MailNotFiledActivities',
  AllAccountsTotal: 'AllAccountsTotal',
  PendingPaymentSummary: 'PendingPaymentSummary',
  PendingPaymentDetails: 'PendingPaymentDetails',
};

export const pendingPaymentSummaryTypes = {
  PendPmtNotReconciled: 'PaymentSummaryNotConciled',
  PendPmtDeclined: 'PaymentSummaryDeclined',
  PendingPaymentSummary: 'PendingPaymentSummary',
  PendPmtNoResponse: 'PaymentSummaryNoResponse',
};

export const pendingPaymentDetailsTypes = {
  PendPmtNotReconciled: 'PaymentDetailsNotConciled',
  PendPmtDeclined: 'PaymentDetailsDeclined',
  PendingPaymentDetails: 'PendingPaymentDetails',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const UserTypes = {
  1: 'Inquiry',
  0: 'Credit',
  2: 'CreditManager',
  3: 'Customer',
  4: 'AlertUser',
  5: 'MaintenanceUser',
  6: 'ITUser',
};

export const ViewNames = {
  UserPayments: 'UserPayments',
  PendingPayments: 'PendingPayments',
  COMMUNICATION: 'Communication',
  EXPECTED_PAYMENTS: 'ExpectedPayment',
  USER_CONTACTS: 'UserContacts',
  TODO_LIST: 'ToDoList',
  PENDING_PAYMENTS_DETAILS: 'PendingPaymentsDetails',
  SCHEDULED_PAYMENTS: 'ScheduledPayments',
  ACCOUNTS: 'Accounts',
  DRAFT_ACTIVITY: 'DraftActivity',
};

export const ColumnNames = {
  Selection: 'Selection',
};

export const reservedChar = {
  LEADING_RESERVED_CHAR_FOR_ALERTS: '^',
  LEADING_RESERVED_CHAR_FOR_VIEW_SETTINGS: '~',
};

export const dashboard = {
  LOCKSTEP_SELF_SERVICE_USAGE: 'Lockstep Self-Service Usage',
  ALLOW_LOCKSTEP_PORTAL: 'AllowLockstepPortal',
  SHOW_DASH_ACTIVITIES: 'ShowDashActivities',
  UNIQUE_VISITS_TODAY: 'Unique visits today',
  CUSTOMERS_VISITED_TODAY: 'Customers visited today',
  UNIQUE_VISITS_THIS_WEEK: 'Unique visits this week',
  CUSTOMERS_VISITED_THIS_WEEK: 'Customers visited this week',
  PAYMENTS_MADE_TODAY: 'Payments made today',
  AMOUNT_COLLECTED_TODAY: 'Amount collected today',
  PAYMENTS_MADE_THIS_WEEK: 'Payments made this week',
  AMOUNT_COLLECTED_THIS_WEEK: 'Amount collected this week',
  SEE_HISTORY_AND_DETAIL: 'See history and detail',
  AVERAGE_DAYS_LATE_BY_INVOICE_AMOUNT: 'Average Days Late by Invoice Amount',
  INVOICE_AMOUNT: 'Invoice Amount',
  OVERALL: 'Overall',
  OVER: 'Over',
  DAYS: 'Days',
  CURRENT_AR_AGING: 'Current A/R Aging',
  BY_INVOICE_DATE: 'By Invoice Date',
  BY_DUE_DATE: 'By Due Date',
  CASH_RECEIPTS_TITLE: 'Cash Receipts',
  OPEN_CREDITS: 'Open_Credits',
  UNAPPLIED_PAYMENTS: 'Unapplied_Payments',
  PRIOR_6_MONTH_DSO_TREND: 'Prior 6 month DSO trend',
  ACTUAL: 'Actual',
  TARGET: 'Target',
  AVERAGE_DAYS_LATE_TREND: 'Average days late trend',
  PROJECTED_CASH_RECEIPTS_DESCRIPTION:
    'Projected cash receipts are calculated leveraging the following system values, in order of priority:',
  PROMISE_PAYMENT_VALUE:
    'Promise Payment Value – Payment timing based on a customer promise',
  PREDICTED_PAYMENT_VALUE:
    'Predicted Payment Value – Calculated based on payment history',
  DUE_DATE: 'Due Date - Calculated based on Due Date',
  UNRESOLVED:
    'Unresolved – Past due invoices without expected or promised date',
  NEXT_MONTH_END: 'Next month-end',
  CURRENT_MONTH_END: 'Current month-end',
  NEXT_30_DAYS: 'Next 30 days',
  NEXT_7_DAYS: 'Next 7 days',
  PROJECTED_CASH_RECEIPTS: 'Projected Cash Receipts',
  CURRENT_AR_BALANCE: 'Current A/R balance',
  AVERAGE_DAYS_LATE: 'Average days late',
  AVERAGE_DAYS_LATE_WEIGHTED: 'Average days late weighted',
  CURRENT_DSO: 'Current DSO',
  TEAM_ACTIVITIES: 'Team Activities',
  TEAM_MEMBER: 'Team Member',
  ACCOUNTS_TOUCHED: 'Accounts Touched',
  INVOICES_TOUCHED: 'Invoices Touched',
  EMAILS_SENT: 'Emails Sent',
  CALLS_MADE: 'Calls Made',
  NO_REPLY_IN_24_HOURS: 'No Reply In 24 Hours',
  TOTAL_COLLECTED: 'Total Collected',
  TOTAL_AGING: 'Total Aging',
};
